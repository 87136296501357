import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import {
    Row, Col, Card, Image, Container,
} from 'react-bootstrap';

import { speakers2021 } from './util';

const PanelistsPanel = (props) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Col data-aos="zoom-in">
            <Card className="pb-4">
                <div className="text-center">
                    <Image src={props.speaker.img} onClick={() => setShowModal(true)} fluid />
                    <Card.Body className="p-0 pt-3">
                        <h3 className="pb-2"><b>{props.speaker.name}</b></h3>
                        <p className="gs-panelist-title">{props.speaker.title}</p>
                    </Card.Body>
                </div>
            </Card>
            <Modal dialogClassName="gs-panelists-modal" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col lg={4} md={5} sm={6} xs={5} className="pull-left mt-3 mb-3 image-format">
                                <Image src={props.speaker.img} fluid />
                            </Col>
                            <Col lg={8} md={7} sm={6} xs={7} className="mt-3 text-image-alignment">
                                <p className="text-left">
                                    <b>{props.speaker.type}</b>
                                </p>
                                <h4 className="text-left mt-3">
                                    {props.speaker.name}
                                </h4>
                                <p className="text-left">
                                    {props.speaker.title}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-left pl-3 pr-3 mt-2">{props.speaker.desc}</p>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Col>
    );
};

const Panelists2021Component = () => {
    const speakerImages = speakers2021.map((speaker, i) => <PanelistsPanel key={i} speaker={speaker} />);

    return (
        <div>
            <Row lg={4} md={2} xs={1} className="d-flex justify-content-center gs-panelists-2021 gs-panelists pt-3" data-aos="fade-up">
                {speakerImages[0]}
                {speakerImages[1]}
                {speakerImages[2]}
            </Row>
            <h4 className="text-center">Moderated by:</h4>
            <Row lg={4} md={2} xs={1} className="d-flex justify-content-center gs-panelists-2021 gs-panelists pt-3" data-aos="fade-up">
                <Col className="d-none d-lg-block" />
                {speakerImages[3]}
                <Col className="d-none d-lg-block"/>
            </Row>
        </div>
    )
}

const CurPanelistsComponent = () => (
    <Container fluid className="gs-block pb-0 bg-dark-grey">
        <h3 className="text-center" data-aos="fade-up">2021 Panel: Innovation in Global Health</h3>
        <h5 className="text-center" data-aos="fade-up">Saturday, March 6th at 11am EST</h5>
        <Panelists2021Component />
    </Container>
);

export default CurPanelistsComponent;
