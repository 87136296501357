import React, { useEffect, useState } from 'react';
import { Container, Row, Form } from 'react-bootstrap';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import LandingComponent from './LandingComponent';
import CompaniesLogoComponent from '../home/CompaniesLogoComponent';
import BodyComponent from './BodyComponent';
import './style.css';
import { SPONSORS_IMAGE } from '../util/constants';

const SignupComponent = () => {
    const [validated, setValidated] = useState(false);
    const signUpHandler = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
    };

    return (
        <Container fluid className="pt-5 pb-5 gs-panel-sign-up gs-sign-up bg-light-yellow">
            <Form noValidate validated={validated} className="p-0 m-0" onSubmit={signUpHandler}>
                <h3 className="text-center">Sign up for updates: </h3>
                <Row className="justify-content-center align-items-center text-center">
                    <div className="mr-auto ml-auto d-flex">
                        <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />
                    </div>
                </Row>
            </Form>
        </Container>
    );
};

const PanelComponent = () => {
    useEffect(() => {
        document.title = 'Thought Leader Panel';
    });

    const companiesLogos = [[SPONSORS_IMAGE.bcg, SPONSORS_IMAGE.uoft, SPONSORS_IMAGE.leap]];

    return (
        <div id="ai-panel">
            <LandingComponent />
            {/* <SignupComponent /> */}
            <CompaniesLogoComponent
                logoSections={companiesLogos}
                className="ai-panel-logos-section"
                heading="Our Sponsors"
                desc=""
            />
            <BodyComponent />
        </div>
    );
};

export default PanelComponent;
