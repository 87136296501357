import ReactGA from 'react-ga';

const gaConfig = {
    id: process.env.REACT_APP_ANALYTICS_ID,
    debug: (process.env.REACT_APP_ANALYTICS_DEBUG === 'true'),
};

export function reactGAInit() {
    ReactGA.initialize(gaConfig.id, { debug: gaConfig.debug });
}

export function reportPageView() {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export function reportEvent(data) {
    ReactGA.event(data);
}
