import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import {
    Navbar, Container, Col, Nav, Image, Button, Collapse,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import {
    SOCIAL_MEDIA_IMAGE, ROUTES, EXTERNAL_LINKS,
} from '../util/constants';
import '../assets/style/hamburger.css';


const Header = () => {
    const [isOpen, setIsOpen] = useState(0);
    const [showBcg, setShowBcg] = useState(0);
    const [navHide, setNavHide] = useState(0);
    const [lastScroll, setLastScroll] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // navbar scrolling variables
    const navBarThresh = 100;

    useEffect(() => {
        // hides navbar when user scrolls down and makes background transparent when at top
        const handleScroll = () => {
            if (!isOpen) {
                if (window.pageYOffset !== 0) setShowBcg(1);
                else setShowBcg(0);

                if (window.pageYOffset > (lastScroll + navBarThresh) || window.pageYOffset < (lastScroll - navBarThresh)) {
                    if (window.pageYOffset > lastScroll) setNavHide(1);
                    else setNavHide(0);
                    setLastScroll(window.pageYOffset);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return function cleanup() {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const onNavigate = () => {
        window.scrollTo(0, 0);
    };

    // hamburger menu animation on click
    const handleHamburgerClick = () => {
        setIsOpen(!isOpen);
        if (!isOpen) setShowBcg(0);
        onNavigate();
    };

    return (
        <div>
            <Navbar className={`gs-navbar pl-3 pl-lg-0 pl-xl-3 ${navHide ? 'nav-hide' : ''} ${showBcg ? 'nav-bcg' : ''}`}>
                <Container fluid className="gs">
                    <Col className={`p-1 p-md-0 p-lg-1 gs-badge ${isOpen ? 'badge-hide' : ''}`}>
                        <NavLink to={ROUTES.LANDING} exact className="gs-navbar-logo">
                            <Image src={SOCIAL_MEDIA_IMAGE.logo} className="p-0 p-xl-3 pb-0" fluid />
                        </NavLink>
                    </Col>

                    <div className="gs-nav-links">
                        <span className="gs-dropdown">
                            <NavLink activeClassName="active" to={ROUTES.HACK_THE_GLOBE} onClick={() => { onNavigate(); }}>Hack The Globe</NavLink>
                            <div className="gs-dropdown-menu">
                                {/* <a href={EXTERNAL_LINKS.HACKER_DASH} target="_blank" rel="noopener noreferrer">Apply Now</a> */}
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-desc`}>What is Hack The Globe?</Link>
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-speaker`}>Keynote Feature</Link>
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-themes`}>Challenge Themes</Link>
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-faq`}>FAQ</Link>
                            </div>
                        </span>
                        <NavLink activeClassName="active" to={ROUTES.PANEL} onClick={() => { onNavigate(); }}>Thought Leader Panel</NavLink>
                        <NavLink activeClassName="active" to={ROUTES.CURRICULUM} onClick={() => { onNavigate(); }}>Curriculum</NavLink>
                        <NavLink activeClassName="active" to={ROUTES.LOCAL_EVENTS} onClick={() => { onNavigate(); }}>Local Events</NavLink>
                        <NavLink activeClassName="active" to={ROUTES.TEAM} onClick={() => { onNavigate(); }}>Our Team</NavLink>
                        <a id="sponsor-button" href={`mailto: ${EXTERNAL_LINKS.EMAIL}`}>Partner with Us</a>
                    </div>

                    <div className="gs-mobile-hamburger">
                        <div onClick={handleHamburgerClick} className={`hamburger hamburger--vortex ${isOpen ? 'is-active' : ''}`}>
                            <div className="hamburger-box">
                                <div className="hamburger-inner" />
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>

            <Nav className={`gs-mobile-nav ${isOpen ? 'mobile-nav-show' : ''}`}>
                <div className="gs-nav-links">
                    <span>
                        <NavLink activeClassName="active" to={ROUTES.HACK_THE_GLOBE} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>Hack The Globe</NavLink>
                        <Button className="gs-dropdown-btn" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </Button>
                        <Collapse in={isDropdownOpen}>
                            <div className="gs-dropdown-menu-mobile">
                                {/* <a href={EXTERNAL_LINKS.HACKER_DASH} target="_blank" className="show-nav-link" rel="noopener noreferrer">Apply Now</a> */}
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-desc`} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>What is Hack The Globe?</Link>
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-speaker`} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>Keynote Feature</Link>
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-themes`} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>Challenge Themes</Link>
                                <Link to={`${ROUTES.HACK_THE_GLOBE}#gs-htg-faq`} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>FAQ</Link>
                            </div>
                        </Collapse>
                    </span>
                    <NavLink activeClassName="active" to={ROUTES.PANEL} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>Thought Leader Panel</NavLink>
                    <NavLink activeClassName="active" to={ROUTES.CURRICULUM} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>Curriculum</NavLink>
                    <NavLink activeClassName="active" to={ROUTES.LOCAL_EVENTS} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>Local Events</NavLink>
                    <NavLink activeClassName="active" to={ROUTES.TEAM} className="show-nav-link" onClick={() => { setIsOpen(0); setIsDropdownOpen(false); }}>Our Team</NavLink>
                    <div className="footer-nav-links">
                        <div className="gs-social-media">
                            <a href={EXTERNAL_LINKS.FACEBOOK} className="show-nav-link" target="_blank" rel="noopener noreferrer">
                                <Image src={SOCIAL_MEDIA_IMAGE.facebook_logo_black} alt="facebook" />
                            </a>
                            <a href={EXTERNAL_LINKS.INSTAGRAM} className="show-nav-link" target="_blank" rel="noopener noreferrer">
                                <Image src={SOCIAL_MEDIA_IMAGE.instagram_logo_black} alt="instagram" />
                            </a>
                            <a href={EXTERNAL_LINKS.LINKEDIN} className="show-nav-link" target="_blank" rel="noopener noreferrer">
                                <Image src={SOCIAL_MEDIA_IMAGE.linkedin_logo_black} alt="linkedin" />
                            </a>
                            <a href={EXTERNAL_LINKS.TWITTER} className="show-nav-link" target="_blank" rel="noopener noreferrer">
                                <Image src={SOCIAL_MEDIA_IMAGE.twitter_logo_black} alt="twitter" />
                            </a>
                        </div>
                    </div>
                </div>
            </Nav>
        </div>
    );
};

export default withRouter(Header);
