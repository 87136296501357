import React from 'react';
import {
    Container, Row, Col, Image,
} from 'react-bootstrap';
import Slider from 'react-slick';
import { HTG_IMAGE } from '../util/constants';

const TestimonialsComponent = () => {
    const settings = {
        arrows: false,
        dots: true,
        adaptiveHeight: true,
    };
    return (
        <Container fluid className="gs-block pl-0 pr-0 pb-0">
            <h3 className="text-center pb-3">Hacker Testimonials</h3>
            <Slider {...settings} className="gs-htg-testimonial-slider w-100">
                <div>
                    <Container fluid style={{ background: 'linear-gradient(149.52deg, #9AD6D6 18.52%, #D4EEEF 116.54%)' }}>
                        <Container className="gs-htg-testimonial-block">
                            <Row>
                                <Col sm={4} data-aos="fade-left" className="pb-3 pb-sm-0 pr-sm-0">
                                    <Image src={HTG_IMAGE.testimonial1} className="h-md-100 float-md-right" fluid alt="Noah Simao" />
                                </Col>
                                <Col sm={8} data-aos="fade-right" className="d-flex">
                                    <div className="mt-auto">
                                        <h5><b>Noah Simao</b></h5>
                                        <h5>Investor @ Georgian Partners &amp; HTG 2020 Finalist</h5>
                                        <p className="d-none d-md-block mb-0">
                                            Coming from a business background, hackathons have always felt like something I could not take part of since
                                            I did not have technical experience. But when I learned about Hack the Globe’s multidisciplinary structure
                                            and its focus on creating a social impact through technology, it fit perfectly with my skills and interests.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <p className="d-md-none pt-sm-3" data-aos="fade-up">
                                Coming from a business background, hackathons have always felt like something I could not take part of since
                                I did not have technical experience. But when I learned about Hack the Globe’s multidisciplinary structure
                                and its focus on creating a social impact through technology, it fit perfectly with my skills and interests.
                            </p>
                        </Container>
                    </Container>
                </div>
                <div>
                    <Container fluid style={{ background: 'linear-gradient(164.04deg, #FFB200 11.12%, #FEE19B 102.87%)' }}>
                        <Container className="gs-htg-testimonial-block">
                            <Row>
                                <Col sm={4} data-aos="fade-left" className="pb-3 pb-sm-0 pr-sm-0">
                                    <Image src={HTG_IMAGE.testimonial2} className="h-md-100 float-md-right" fluid alt="Chloe Shin-Gay" />
                                </Col>
                                <Col sm={8} data-aos="fade-right" className="d-flex">
                                    <div className="mt-auto">
                                        <h5><b>Chloe Shin-Gay</b></h5>
                                        <h5>YNCN Operations &amp; Logistics Associate HTG 2020 Finalist</h5>
                                        <p className="d-none d-md-block mb-0">
                                            One of the most valuable aspects of Hack the Globe was the wide range of support offered to help
                                            participants discover social issues to work on, improve their technical skills as well as flesh
                                            out a project idea.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <p className="d-md-none pt-sm-3" data-aos="fade-up">
                                One of the most valuable aspects of Hack the Globe was the wide range of support offered to help
                                participants discover social issues to work on, improve their technical skills as well as flesh
                                out a project idea.
                            </p>
                        </Container>
                    </Container>
                </div>
                <div>
                    <Container fluid style={{ background: 'linear-gradient(161.8deg, #EC7070 12.37%, #F09594 95.76%) ' }}>
                        <Container className="gs-htg-testimonial-block">
                            <Row>
                                <Col sm={4} data-aos="fade-left" className="pb-3 pb-sm-0 pr-sm-0">
                                    <Image src={HTG_IMAGE.testimonial3} className="h-md-100 float-md-right" fluid alt="Hui Wen" />
                                </Col>
                                <Col sm={8} data-aos="fade-right" className="d-flex">
                                    <div className="mt-auto">
                                        <h5><b>Hui Wen</b></h5>
                                        <h5>Loran Scholar &amp; HTG 2020 Finalist </h5>
                                        <p className="d-none d-md-block mb-0">
                                            As a social science student, I have always wanted to apply my knowledge of global issues to create
                                            real-world solutions. Hack the Globe gave me the perfect opportunity to combine my interest in
                                            international development with hands-on application with digital innovation.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <p className="d-md-none pt-sm-3" data-aos="fade-up">
                                As a social science student, I have always wanted to apply my knowledge of global issues to create
                                real-world solutions. Hack the Globe gave me the perfect opportunity to combine my interest in
                                international development with hands-on application with digital innovation.
                            </p>
                        </Container>
                    </Container>
                </div>
            </Slider>
        </Container>
    );
};

export default TestimonialsComponent;
