import React from 'react';

export const settingsLg = {
    centerMode: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    focusOnSelect: true,
};

export const settings = {
    centerMode: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    focusOnSelect: true,
    dots: true,
};

export const colours = ['0C2F61', 'FAD867', '4A2B7E'];

export const UofT = [
    {
        name: 'International Women\'s Day Gala',
        location: 'University of Toronto',
        description: 'The International Women’s Day Gala brought together 120 student leaders across the University of Toronto to celebrate the incredible achievements of women in STEM through a speaker panel, performances, and dinner. The night included a networking session with female leaders in STEM and emphasized interdisciplinary problem solving to complex real-world challenges.',
    },
    {
        name: 'Global Engineering Fair',
        location: 'University of Toronto',
        description: ' The 4th annual Global Engineering Fair exposed over 200 students to complex interdisciplinary challenges, gender equality problems, and the Sustainable Development Goals through games and engaging activities. Students cycled through a diverse set of educational activities, learned about global challenges, and won prizes.',
    },
];

export const UofW = [
    {
        name: 'Climate Crisis Podcast & Discussion Circle',
        location: 'University of Waterloo',
        description: '5 student delegates from EWB, Climate Students, Fossil Free UW, and Waterloo’s Sustainable Campus Initiative gathered for a podcast recording on the climate crisis, highlighting the current global impacts and potential solutions to the issue caused by climate change. A public discussion circle held after the podcast invited club members to join the delegates in a group conversation on this topic.',
    },
    {
        name: 'Game Design Showcase Night',
        location: 'University of Waterloo',
        description: 'Students from the Design, Systems, and Society (SYDE 261) class publicly presented their course project, an educational prototype game that explored the impacts of emerging technology on society and global issues such as climate change, overpopulation, international trade and epidemics. Over 150 students from all faculties attended and were encouraged to play the students’ games and provided feedback to the design teams.',
    },
];

export const UWO = [
    {
        name: 'Imperfect Tech in an Imperfect Society Speaker Panel',
        location: 'University of Western Ontario',
        description: <>
            The panel brought together
            {' '}
            <a href="https://www.linkedin.com/in/tracicheng/" target="_blank" rel="noopener noreferrer">Traci Cheng</a>
            {' '}
            and
            {' '}
            <a href="https://www.linkedin.com/in/iarariosgonzalez/" target="_blank" rel="noopener noreferrer">Iara Rios</a>
            , two thought leaders in the field of Artificial Intelligence, to discuss the impact of emerging technology on society. The panelists explored topics including how technology can be applied to tackle social impact issues, the challenge with technological bias and diversity, and how technology affects socio-economic inequality, concluding the night with an engaging Q&A session.
            {' '}
        </>,
    },
];
