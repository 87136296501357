import { PEOPLE_IMAGE } from '../util/constants';

export const teamSections = [
    {
        teamName: 'Leadership Team',
        people: [
            {
                name: 'Malik Ismail',
                title: 'Consultant at BCG',
                position: 'Founder & Executive Director',
                image: PEOPLE_IMAGE.malik_ismail,
                link: 'https://www.linkedin.com/in/malik-ismail-14b04157/',
            },
            {
                name: 'Morris Huang',
                title: 'Process Engineer at Air Liquide Japan',
                position: 'Senior Managing Director',
                image: PEOPLE_IMAGE.morris_huang,
                link: 'https://www.linkedin.com/in/morrishuang20/',
            },
            {
                name: 'John Kim',
                title: 'Associate at BCG',
                position: 'Managing Director, Hack the Globe',
                image: PEOPLE_IMAGE.john_kim,
                link: 'https://www.linkedin.com/in/johnkim96/',
            },
            {
                name: 'Julian Ashworth',
                title: 'Incoming Associate at BCG',
                position: 'Managing Director, Hack the Globe',
                image: PEOPLE_IMAGE.julian_ashworth,
                link: 'https://www.linkedin.com/in/julian-ashworth/',
            },
            {
                name: 'Catherine  Glossop',
                title: 'Researcher at the UTIAS',
                position: 'Managing Director, Hack the Globe',
                image: PEOPLE_IMAGE.catherine_glossop,
                link: 'https://www.linkedin.com/in/catherine-g-659393194/',
            },
            {
                name: 'Alyf Janmohamed',
                title: 'PhD Student at Cornell University',
                position: 'Managing Director, Thought Leader Panel',
                image: PEOPLE_IMAGE.alyf_janmohamed,
                link: 'https://www.linkedin.com/in/alyf-janmohamed-b21a93bb',
            },
            {
                name: 'Julia Chae',
                title: 'Research at the TRAIL',
                position: 'Managing Director, Thought Leader Panel',
                image: PEOPLE_IMAGE.julia_chae,
                link: 'https://www.linkedin.com/in/julia-chae/',
            },
            {
                name: 'Esther Yoo',
                title: 'Researcher at the Hoffma Lab (PMCRT)',
                position: 'Managing Director, Curriculum',
                image: PEOPLE_IMAGE.esther_yoo,
                link: 'www.linkedin.com/in/esther-y',
            },
            {
                name: 'Samantha Unger',
                title: 'Researcher at the ISML',
                position: 'Managing Director, Curriculum',
                image: PEOPLE_IMAGE.samantha_unger,
                link: 'https://www.linkedin.com/in/samantha-unger/',
            },
            {
                name: 'Ellen Chen',
                title: 'Software Team Co-Lead at Rover Space Xploration',
                position: 'Managing Director, Growth & Partnerships',
                image: PEOPLE_IMAGE.ellen_chen,
                link: 'https://www.linkedin.com/in/ellenxwchen/',
            },
            {
                name: 'Dasha Pushkareva',
                title: 'Program Manager at Microsoft',
                position: 'Managing Director, Marketing & Digital',
                image: PEOPLE_IMAGE.dasha_pushkareva,
                link: 'https://www.linkedin.com/in/daria-pushkareva/',
            },
            {
                name: 'Leila Martin',
                title: 'Associate at BCG',
                position: 'Managing Director, Future of Education',
                image: PEOPLE_IMAGE.leila_martin,
                link: 'https://www.linkedin.com/in/leilamartin/',
            },
            {
                name: 'Roya Rahnejat',
                title: 'EIT & Advisory Consultant at Arup',
                position: 'Managing Director, Future of Education',
                image: PEOPLE_IMAGE.roya_rahnejat,
                link: 'https://www.linkedin.com/in/roya-rahnejat/',
            },
            {
                name: 'Henry Zhang',
                title: 'Business Analyst at McKinsey & Co.',
                position: 'Curriculum Advisor',
                image: PEOPLE_IMAGE.henry_zhang,
                link: 'https://www.linkedin.com/in/henry-bohan-zhang/',
            },
            {
                name: 'Phil Lu',
                title: 'Fellow at School of Cities, University of Toronto',
                position: 'Hack the Globe Advisor',
                image: PEOPLE_IMAGE.phil_lu,
                link: 'https://www.linkedin.com/in/philipqlu/',
            },
        ],
    },
    {
        teamName: 'Hack the Globe',
        people: [
            {
                name: 'John Kim',
                title: 'Associate at BCG',
                position: 'Managing Director, Hack the Globe',
                image: PEOPLE_IMAGE.john_kim,
                link: 'https://www.linkedin.com/in/johnkim96/',
            },
            {
                name: 'Julian Ashworth',
                title: 'Incoming Associate at BCG',
                position: 'Managing Director, Hack the Globe',
                image: PEOPLE_IMAGE.julian_ashworth,
                link: 'https://www.linkedin.com/in/julian-ashworth/',
            },
            {
                name: 'Catherine  Glossop',
                title: 'Researcher at UTIAS',
                position: 'Managing Director, Hack the Globe',
                image: PEOPLE_IMAGE.catherine_glossop,
                link: 'https://www.linkedin.com/in/catherine-g-659393194/',
            },
            {
                name: 'Noah Simao',
                title: 'Growth Equity Investment Analyst at Georgian Partners',
                position: 'Director of Sponsorships',
                image: PEOPLE_IMAGE.noah_simao,
                link: 'https://www.linkedin.com/in/noah-simao/',
            },
            {
                name: 'Hui Wen  Zheng',
                title: 'Researcher at the REACH Alliance',
                position: 'Director of Sponsorships',
                image: PEOPLE_IMAGE.huiwen_zheng,
                link: 'https://www.linkedin.com/in/huiwenzh/',
            },
            {
                name: 'Logan  Kieller',
                title: 'Project Control Officer at RBC',
                position: 'Director of External Logistics',
                image: PEOPLE_IMAGE.logan_kieller,
                link: 'http://linkedin.com/in/logan-kieller',
            },
            {
                name: 'Andy Jiang',
                title: 'Software Engineer at IBM',
                position: 'Director of External Logistics',
                image: PEOPLE_IMAGE.andy_jiang,
                link: 'https://www.linkedin.com/in/andyjiang99/',
            },
            {
                name: 'Aidan Grenville',
                title: 'Researcher at the Sargent Group',
                position: 'Director of Internal Logistics',
                image: PEOPLE_IMAGE.aidan_grenville,
                link: 'https://www.linkedin.com/in/aidan-grenville-a08242150/',
            },
            {
                name: 'Zane Shad',
                title: 'Computer Science and Business Student at Western University',
                position: 'Director of Internal Logistics',
                image: PEOPLE_IMAGE.zane_shad,
                link: 'http://linkedin.com/in/zane-shad',
            },
            {
                name: 'Mitchell Li',
                title: 'Analyst at Loblaw Companies Limited',
                position: 'Jr. Logistics Director',
                image: PEOPLE_IMAGE.mitchell_li,
                link: 'https://www.linkedin.com/in/mitchell-tz-li/',
            },
        ],
    },
    {
        teamName: 'Thought Leadership Panel',
        people: [
            {
                name: 'Alyf Janmohamed',
                title: 'PhD Student at Cornell University',
                position: 'Managing Director, Thought Leader Panel',
                image: PEOPLE_IMAGE.alyf_janmohamed,
                link: 'https://www.linkedin.com/in/alyf-janmohamed-b21a93bb',
            },
            {
                name: 'Julia Chae',
                title: 'Research at the TRAIL',
                position: 'Managing Director, Thought Leader Panel',
                image: PEOPLE_IMAGE.julia_chae,
                link: 'https://www.linkedin.com/in/julia-chae/',
            },
        ],
    },
    {
        teamName: 'Curriculum',
        people: [
            {
                name: 'Esther Yoo',
                title: 'Researcher at the Hoffma Lab (PMCRT)',
                position: 'Managing Director, Curriculum',
                image: PEOPLE_IMAGE.esther_yoo,
                link: 'https://www.linkedin.com/in/esther-y/',
            },
            {
                name: 'Samantha Unger',
                title: 'Researcher at the ISML',
                position: 'Managing Director, Curriculum',
                image: PEOPLE_IMAGE.samantha_unger,
                link: 'https://www.linkedin.com/in/samantha-unger/',
            },
            {
                name: 'Kieran Cyphus',
                title: 'Software Development Intern at Common Wealth',
                position: 'Campus Lead, University of Toronto',
                image: PEOPLE_IMAGE.kieran_cyphus,
                link: 'https://www.linkedin.com/in/kieran-cyphus-b93489179/',
            },
            {
                name: 'Jina Yazdanpanah',
                title: 'Mobility Engineering Specialist at Bell Canada',
                position: 'Campus Lead, University of Toronto',
                image: PEOPLE_IMAGE.jina_yazdanpanah,
                link: 'https://www.linkedin.com/in/jinayazdanpanah/',
            },
            {
                name: 'Tina Nia',
                title: 'Hydronics COOP student at HTS Engineering Group Ltd',
                position: 'Campus Lead, University of Waterloo',
                image: PEOPLE_IMAGE.tina_nia,
                link: 'https://www.linkedin.com/in/tina-nia/',
            },
            {
                name: 'Connor McLennan',
                title: 'Customer Service Specialist at PepsiCo',
                position: 'Campus Lead, Western University',
                image: PEOPLE_IMAGE.connor_mclennan,
                link: 'https://www.linkedin.com/in/connor-mclennan-2021/',
            },
            {
                name: 'Felix Yan',
                title: 'Series Lead at Aecon Nuclear and SNC Lavalin Joint Venture',
                position: 'Campus Lead, Western University',
                image: PEOPLE_IMAGE.felix_yan,
                link: 'https://www.linkedin.com/in/felixkyan/',
            },
            {
                name: 'Catherine Chen',
                title: 'Software Engineering Student at McMaster University',
                position: 'Campus Lead, McMaster University',
                image: PEOPLE_IMAGE.catherine_chen,
                link: 'https://www.linkedin.com/in/cathkychen/',
            },
            {
                name: 'Ayesha Raza',
                title: 'B.Sc.Kin Candidate at McMaster University',
                position: 'Campus Lead, McMaster University',
                image: PEOPLE_IMAGE.ayesha_raza,
                link: 'https://www.linkedin.com/in/ayesharaza00/',
            },
            {
                name: 'Brendan Lai',
                title: 'Integrated Engineering Student at UBC',
                position: 'Campus Lead, University of British Columbia',
                image: PEOPLE_IMAGE.brendan_lai,
                link: 'https://www.linkedin.com/in/brendan-lai-176460182/',
            },
            {
                name: 'Mia Nguyen',
                title: 'Contributor at NAOC',
                position: 'External Relations Director, Academic Partnerships Coordinator, University of Toronto',
                image: PEOPLE_IMAGE.mia_nguyen,
                link: 'https://www.linkedin.com/in/mia-nguyen/',
            },
            {
                name: 'Atharv Agrawal',
                title: 'Student Consultant at IBM Pro Bono Initiative',
                position: 'Academic Partnership Coordinator, University of Toronto',
                image: PEOPLE_IMAGE.atharv_agrawal,
                link: 'https://www.linkedin.com/in/atharv-agrawal/',
            },
            {
                name: 'Kailin Hong',
                title: 'HMI Research Engineer at Huawei',
                position: 'Academic Partnership Coordinator, University of Toronto',
                image: PEOPLE_IMAGE.kailin_hong,
                link: 'https://www.linkedin.com/in/kailin-hong/',
            },
            {
                name: 'Afnan Rahman',
                title: 'B2C Director at AIESEC Canada',
                position: 'Academic Partnership Coordinator, University of Toronto',
                image: PEOPLE_IMAGE.afnan_rahman,
                link: 'https://www.linkedin.com/in/afnan-rahman-47ab52174/',
            },
            {
                name: 'Annie Zou',
                title: 'Systems Design Engineering Student at the University of Waterloo',
                position: 'Academic Partnerships Coordinator, University of Waterloo',
                image: PEOPLE_IMAGE.annie_zou,
                link: 'https://ca.linkedin.com/in/xinyi-zou',
            },
            {
                name: 'Tamara Chepngtich Biwott',
                title: 'Business Student at Western University',
                position: 'Academic Partnerships Coordinator, Western University',
                image: PEOPLE_IMAGE.tamara_biwott,
                link: 'https://www.linkedin.com/in/tamara-biwott-4a263a195/',
            },
            {
                name: 'Vyoma Fadia',
                title: 'Marketing Strategy Intern at Heidi Lynne Coaching & Consulting',
                position: 'Academic Partnerships Coordinator, Western University',
                image: PEOPLE_IMAGE.vyoma_fadia,
                link: 'https://www.linkedin.com/in/vyomafadia/',
            },
            {
                name: 'Nicolas Bottger Malaga',
                title: 'Strategy Summer Intern at Scotiabank',
                position: 'External Relations Director, Western University',
                image: PEOPLE_IMAGE.nicolas_bottger,
                link: 'https://www.linkedin.com/in/nicolasbottgerm/',
            },
            {
                name: 'Alex Mark',
                title: 'Software Development Engineer Intern at Amazon',
                position: 'External Relations Director, University of Toronto',
                image: PEOPLE_IMAGE.alex_mark,
                link: 'https://www.linkedin.com/in/alexander-mark-4518ab15a/',
            },
            {
                name: 'Henry Xu',
                title: 'Graduate Computer Engineering Student at UofT',
                position: 'External Relations Director, University of Toronto',
                image: PEOPLE_IMAGE.henry_xu,
                link: 'https://www.linkedin.com/in/xuhenry/',
            },
        ],
    },
    {
        teamName: 'Local Events',
        people: [
            {
                name: 'Morris Huang',
                title: 'Process Engineer at Air Liquide Japan',
                position: 'Senior Managing Director',
                image: PEOPLE_IMAGE.morris_huang,
                link: 'https://www.linkedin.com/in/morrishuang20/',
            },
            {
                name: 'Ellen Chen',
                title: 'Software Team Co-Lead at Rover Space Xploration',
                position: 'Managing Director, Growth & Partnerships',
                image: PEOPLE_IMAGE.ellen_chen,
                link: 'https://www.linkedin.com/in/ellenxwchen/',
            },
            {
                name: 'Martina Facchin',
                title: 'Researcher at the REACH Alliance',
                position: 'Director of Growth, University of Toronto',
                image: PEOPLE_IMAGE.martina_facchin,
                link: 'https://www.linkedin.com/in/martina-facchin/',
            },
            {
                name: 'Sean Xie',
                title: 'Business Analyst at eBay',
                position: 'Director of Growth, University of Toronto',
                image: PEOPLE_IMAGE.sean_xie,
                link: 'https://www.linkedin.com/in/seanxiezc/',
            },
            {
                name: 'Sarah Chun',
                title: 'Data Analyst at Optimize Group Inc.',
                position: 'Director of Growth, University of Waterloo',
                image: PEOPLE_IMAGE.sarah_chun,
                link: 'https://www.linkedin.com/in/sarah-chun-190/',
            },
            {
                name: 'Ruchi Hamal',
                title: 'Environmental Engineering Student at the University of Waterloo',
                position: 'Director of Growth, University of Waterloo',
                image: PEOPLE_IMAGE.ruchi_hamal,
                link: 'https://www.linkedin.com/in/ruchi-hamal/',
            },
            {
                name: 'Anna Heer',
                title: 'Rewards Consulting Intern at Willis Towers Watson',
                position: 'Director of Growth, Western University',
                image: PEOPLE_IMAGE.anna_heer,
                link: 'https://www.linkedin.com/in/annaheer/',
            },
            {
                name: 'Akinori Kimura',
                title: 'Electrical Engineering Student at UofT',
                position: 'Campus Lead, University of Toronto',
                image: PEOPLE_IMAGE.akinori_kimura,
                link: 'https://www.linkedin.com/in/akinori-kimura-5462aa17b/',
            },
            {
                name: 'Mimi Le',
                title: 'Student Teacher at University of Toronto',
                position: 'Campus Lead, University of Toronto',
                image: PEOPLE_IMAGE.mimi_le,
                link: 'https://www.linkedin.com/in/mimile246/',
            },
            {
                name: 'Min Namgung',
                title: 'Mechanical Engineering Student at UofT',
                position: 'Campus Lead, University of Toronto',
                image: PEOPLE_IMAGE.min_namgung,
                link: 'www.linkedin.com/in/min-namgung',
            },
            {
                name: 'Madison Wong',
                title: 'Engineering Science Student at UofT',
                position: 'Campus Lead, University of Toronto',
                image: PEOPLE_IMAGE.madison_wong,
                link: 'https://www.linkedin.com/in/madisonwong210/',
            },
            {
                name: 'Maria Fraser Semenoff',
                title: 'Environmental Engineering Student at the University of Waterloo',
                position: 'Campus Lead, University of Waterloo',
                image: PEOPLE_IMAGE.maria_fraser,
                link: 'https://www.linkedin.com/in/maria-fraser-semenoff/',
            },
            {
                name: 'Iris Guo',
                title: 'Product Manager at Blackberry',
                position: 'Campus Lead, University of Waterloo',
                image: PEOPLE_IMAGE.iris_guo,
                link: 'https://www.linkedin.com/in/iris-guo/',
            },
            {
                name: 'Hailey Reynolds',
                title: 'Environmental Engineering Student at the University of Waterloo',
                position: 'Campus Lead, University of Waterloo',
                image: PEOPLE_IMAGE.hailey_reynolds,
                link: 'https://www.linkedin.com/in/hailey-reynolds-12b59014a/',
            },
            {
                name: 'Sandra Yang',
                title: 'Product Manager Intern at SAP',
                position: 'Campus Lead, University of Waterloo',
                image: PEOPLE_IMAGE.sandra_yang,
                link: 'https://www.linkedin.com/in/axnyang1/',
            },
            {
                name: 'Annie Zou',
                title: 'Systems Design Engineering Student at the University of Waterloo',
                position: 'Campus Lead, University of Waterloo',
                image: PEOPLE_IMAGE.annie_zou,
                link: 'https://ca.linkedin.com/in/xinyi-zou',
            },
            {
                name: 'Elliot Lam',
                title: 'Ivey HBA & Software Engineering Dual Degree Candidate at Western University',
                position: 'Campus Lead, Western University',
                image: PEOPLE_IMAGE.elliot_lam,
                link: 'https://www.linkedin.com/in/elliotlam/',
            },
            {
                name: 'Alvina Lin',
                title: 'Business Technology Consulting Intern at Sweetome Vancouver',
                position: 'Campus Lead, Western University',
                image: PEOPLE_IMAGE.alvina_lin,
                link: 'https://www.linkedin.com/in/alvina-lin/',
            },
            {
                name: 'Henry McArdle',
                title: 'Strategy & Analytics Intern at Enverus',
                position: 'Campus Lead, Western University',
                image: PEOPLE_IMAGE.henry_mcardle,
                link: 'https://www.linkedin.com/in/henry-mcardle/',
            },
            {
                name: 'Darren Tan',
                title: 'Business Development Intern at ScrumGenius',
                position: 'Campus Lead, Western University',
                image: PEOPLE_IMAGE.darren_tan,
                link: 'https://www.linkedin.com/in/darrentanzx/',
            },
            {
                name: 'Kitan Ademidun',
                title: 'Engineering and Ivey Business Dual Degree Candidate at Western University',
                position: 'Advisor, Western University',
                image: PEOPLE_IMAGE.kitan_ademidun,
                link: 'https://www.linkedin.com/in/kitan-ademidun/',
            },
            {
                name: 'Mia Van Oirschot',
                title: 'Co-ed Programs Coordinator at the Faculty of Engineering at Western University',
                position: 'Advisor, Western University',
                image: PEOPLE_IMAGE.mia_vanoirschot,
                link: 'https://www.linkedin.com/in/mia-van-oirschot-a58342198/',
            },
        ],
    },
    {
        teamName: 'Marketing and Digital',
        people: [
            {
                name: 'Dasha Pushkareva',
                title: 'Program Manager at Microsoft',
                position: 'Managing Director, Marketing & Digital',
                image: PEOPLE_IMAGE.dasha_pushkareva,
                link: 'https://www.linkedin.com/in/daria-pushkareva/',
            },
            {
                name: 'Susan Chen',
                title: 'Software Intern at Twitch',
                position: 'Director of Software Engineering',
                image: PEOPLE_IMAGE.susan_chen,
                link: 'https://www.linkedin.com/in/susachen/',
            },
            {
                name: 'Chloe  Shin-Gay',
                title: '3rd-year Engineering Science Student at the University of Toronto',
                position: 'Director of Marketing',
                image: PEOPLE_IMAGE.chloe_shingay,
                link: 'https://www.linkedin.com/in/chloe-shin-gay-b67723176/',
            },
            {
                name: 'Karolina  Xin',
                title: 'Software Engineer Intern at Microsoft',
                position: 'Director of Marketing',
                image: PEOPLE_IMAGE.karolina_xin,
                link: 'https://www.linkedin.com/in/karolinaxin/',
            },
            {
                name: 'Alexa Tumaneng',
                title: '3rd-year Chemical Engineering Student at the University of Toronto',
                position: 'Director of Social Media and Communications',
                image: PEOPLE_IMAGE.alexa_tumaneng,
                link: 'https://www.linkedin.com/in/edrianne-alexa-t-978bb5125/',
            },
            {
                name: 'Selina Wan',
                title: '3rd-year Engineering Science Student at the University of Toronto',
                position: 'Director of Social Media and Communications',
                image: PEOPLE_IMAGE.selina_wan,
                link: 'http://www.linkedin.com/in/selinawan',
            },
            {
                name: 'Helen Lin',
                title: 'Software Engineer Intern at Shopify',
                position: 'Product Designer',
                image: PEOPLE_IMAGE.helen_lin,
                link: 'https://www.linkedin.com/in/helenxlin/',
            },
            {
                name: 'Zoie Hou',
                title: 'Software Engineer Co-op at Intelligent City',
                position: 'Graphic Designer',
                image: PEOPLE_IMAGE.zoie_hou,
                link: 'https://www.linkedin.com/in/zoie-hou-505478178/',
            },
            {
                name: 'Irene Lee',
                title: 'Software Engineer Intern at Closing Folders',
                position: 'Graphic Designer',
                image: PEOPLE_IMAGE.irene_lee,
                link: 'https://www.linkedin.com/in/irenelee914/',
            },
            {
                name: 'Shaahana Naufal',
                title: 'Product Designer at Ontario Digital Service',
                position: 'Graphic Designer',
                image: PEOPLE_IMAGE.shaahana_naufal,
                link: 'https://www.linkedin.com/in/shaahana-naufal/',
            },
            {
                name: 'Mymy Tran',
                title: 'Research Student at ISAE-SUPAERO',
                position: 'Data Scientist',
                image: PEOPLE_IMAGE.mymy_tran,
                link: 'https://www.linkedin.com/in/mymy-tran-36474014a',
            },
            {
                name: 'Rachel Wong',
                title: 'Analytics Engineer at Scotiabank',
                position: 'Full Stack Engineer',
                image: PEOPLE_IMAGE.rachel_wong,
                link: 'https://www.linkedin.com/in/rachelwonghw/',
            },
            {
                name: 'Ayla Orucevic',
                title: 'Full Stack Software Developer at Martello Technologies',
                position: 'Full Stack Engineer',
                image: PEOPLE_IMAGE.ayla_orucevic,
                link: 'https://www.linkedin.com/in/ayla/',
            },
            {
                name: 'Aashan Meiyappan',
                title: 'Data Scientist Intern at Intact',
                position: 'Data Engineer',
                image: PEOPLE_IMAGE.aashan_meiyappan,
                link: 'https://www.linkedin.com/in/aashanm',
            },
            {
                name: 'Yixin Tian',
                title: '3rd-year Electrical and Computer Engineering Student at the Unviersity of Toronto',
                position: 'Data Engineer',
                image: PEOPLE_IMAGE.yixin_tian,
                link: 'https://www.linkedin.com/in/yixintian/',
            },
        ],
    },
];
