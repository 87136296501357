import React from 'react';
import {
    Row, Col, Container,
} from 'react-bootstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SPEAKER_IMAGE, EXTERNAL_LINKS } from '../util/constants';

const LandingComponent = () => (
    <Container
        data-aos="fade-right"
        fluid
        className="gs-block gs-landing"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${SPEAKER_IMAGE.landing})` }}
    >
        <Container>
            <Row className="h-100">
                <Col lg={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} className="text-right text-white text-image-alignment">
                    <h1 className="pb-2 d-none d-sm-block">
                        An inspiring conversation with global, cross-disciplinary thought leaders
                    </h1>
                    <h3 className="pb-2 d-sm-none">
                        An inspiring conversation with global, cross-disciplinary thought leaders
                    </h3>
                    <p>
                        We bring together global experts to discuss how cutting edge technology can be used to tackle our planet’s
                        biggest challenges, and the role of different sectors in implementing sustainable solutions.
                    </p>
                    <p className="text-yellow">March 6th, 2021 11am EST</p>
                    <a className="btn" href={EXTERNAL_LINKS.PANEL_REG} target="_blank" rel="noopener noreferrer">Register <FontAwesomeIcon icon={faArrowRight} /></a>
                </Col>
            </Row>
        </Container>
    </Container>
);

export default LandingComponent;
