import React, { useEffect } from 'react';
import { reportPageView } from '../util/analytics';

import LandingComponent from './LandingComponent';
import BodyComponent from './BodyComponent';

import './style.css';

const HomeComponent = () => {
    useEffect(() => {
        reportPageView();
        document.title = 'Global Spark';
    });

    return (
        <div id="home">
            <LandingComponent />
            <BodyComponent />
        </div>
    );
};

export default HomeComponent;
