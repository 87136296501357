import React from 'react';
import {
    Row, Col, Image, Container,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { EVENTS_IMAGE, ROUTES } from '../util/constants';
import ScheduleComponent from './ScheduleComponent';

const WhatWeDoComponent = () => (
    <div>
        <Container className="gs-block">
            <Row>
                <Col md={5} data-aos="fade-right">
                    <h3 className="text-center pb-3 text-dark-blue">What We Do</h3>
                </Col>
                <Col md={7} data-aos="fade-left">
                    <p>
                        We know that students learn best when they actively apply the skills they develop from the classroom to
                        out-of-classroom experiences that complement their studies.
                        To build on our
                        {' '}
                        <NavLink to={ROUTES.CURRICULUM}>Curriculum Initiatives</NavLink>
                        , we provide hands-on
                        learning opportunities for students to further hone their multidisciplinary problem solving approaches.
                        Through partnerships with over 20 student organizations and university faculties across Canada,
                        Global Spark brings students a collection of engaging events including Speaker Series, Design
                        Competitions, and Student Fairs.
                    </p>
                </Col>
            </Row>
        </Container>
    </div>
);

const EventArchetypesComponent = () => (
    <Container fluid className="gs-block pr-5 pl-5 pr-lg-0 pl-lg-0">
        <Row className="pb-4 justify-content-center" data-aos="fade-up">
            <Col>
                <h2 className="text-center text-dark-blue">Types of Events That We Organize</h2>
            </Col>
        </Row>
        <Row data-aos="fade-up" className="align-items-center">
            <Col lg={6} className="p-0">
                <Image src={EVENTS_IMAGE.speaker_series} alt="Speaker series" fluid />
            </Col>
            <Col lg={6} className="p-3 p-xl-5 pr-0">
                <h4 className="pb-3 pl-lg-5">Speaker Series</h4>
                <p className="pl-lg-5">
                    We bring together experts and thought leaders from academia, business, tech, and the non-profit sectors to
                    discuss the most pressing global challenges, ranging from climate change to socio-economic inequality.
                    Students are able to dive deep into conversation with panelists and gain insight into how different sectors
                    are working together to tackle these complex problems and implement sustainable solutions.
                </p>
            </Col>
        </Row>
        <Row data-aos="fade-up" className="align-items-center">
            <Col lg={6} className="order-lg-2 p-0">
                <Image src={EVENTS_IMAGE.design_competition} alt="Speaker series" fluid />
            </Col>
            <Col lg={6} className="order-lg-1 p-3 text-left text-lg-right p-xl-5 pr-0">
                <h4 className="pb-3 pr-lg-5">Design Competitions</h4>
                <p className="pr-lg-5">
                    These competitions challenge students to develop, build, and present their solution to a creative design
                    problem set in a global context with affordable, everyday materials and tools. Participants are forced to
                    think outside of the traditional technical mindset of problem solving and pushed to consider the socio-economic,
                    cultural, and political dimensions of the problem as they work to develop an innovative and sustainable solution.
                </p>
            </Col>
        </Row>
        <Row data-aos="fade-up" className="align-items-center">
            <Col lg={6} className="p-0">
                <Image src={EVENTS_IMAGE.public_events} alt="Speaker series" fluid />
            </Col>
            <Col lg={6} className="p-3 p-xl-5 pr-0">
                <h4 className="pb-3 pl-lg-5">Student Fairs</h4>
                <p className="pl-lg-5">
                    We organize large, student-run fairs that showcase the multitude of research and activities being done at local
                    universities to tackle global challenges. Our fairs deliver this content through engaging hands-on activities,
                    enabling students to cycle through different stations and learn about various topics in a short amount of time
                    while earning prizes.
                </p>
            </Col>
        </Row>
    </Container>
);

const BodyComponent = () => (
    <div id="local-events-body">
        <WhatWeDoComponent />
        <EventArchetypesComponent />
        <ScheduleComponent />
    </div>
);

export default BodyComponent;
