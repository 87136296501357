import React, { useState } from 'react';
import {
    Tabs, Tab, Container,
} from 'react-bootstrap';
import Slider from 'react-slick';

import { impactTabs } from './util';

const ImpactTabComponent = (props) => (
    <div {...props} style={{ backgroundImage: `linear-gradient(0deg, rgba(10, 10, 10, 0.5), rgba(10, 10, 10, 0.5)), url(${props.impact.img})` }}>
        <div className="mt-auto">
            <h5>{props.impact.title}</h5>
            <p>
                {props.impact.desc}
            </p>
        </div>
    </div>
);

const ImpactComponent = () => {
    const [sliderObj, setSlider] = useState(0);
    const [key, setKey] = useState(0);
    const settings = {
        speed: 200,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
        beforeChange: (current, next) => { setKey(next); },
    };
    return (
        <div>
            <Container fluid className="bg-light-blue gs-block pl-0 pr-0">
                <h3 className="text-center text-dark-blue">What makes Hack The Globe Unique?</h3>
                <Tabs
                    className="gs-tabs pt-2 pb-4"
                    defaultActiveKey="0"
                    activeKey={key}
                    onSelect={(k) => sliderObj.slickGoTo(k)}
                >
                    <Tab eventKey="0" title="Multidisciplinary Teams" className="slick-center">
                        <ImpactTabComponent impact={impactTabs.multidisciplinary} className="gs-htg-impact-slide d-flex align-items-end flex-column d-xl-none" />
                    </Tab>
                    <Tab eventKey="1" title="Two-Week Timeline" className="slick-center">
                        <ImpactTabComponent impact={impactTabs.twoWeek} className="gs-htg-impact-slide d-flex align-items-end flex-column d-xl-none" />
                    </Tab>
                    <Tab eventKey="2" title="Global NGO Partners" className="slick-center">
                        <ImpactTabComponent impact={impactTabs.ngo} className="gs-htg-impact-slide d-flex align-items-end flex-column d-xl-none" />
                    </Tab>
                    <Tab eventKey="3" title="Direct BCG Mentorship" className="slick-center">
                        <ImpactTabComponent impact={impactTabs.mentorship} className="gs-htg-impact-slide d-flex align-items-end flex-column d-xl-none" />
                    </Tab>
                </Tabs>
                <Slider {...settings} ref={(slider) => (setSlider(slider))} className="gs-htg-impact-slider d-none d-xl-block">
                    <div>
                        <ImpactTabComponent className="gs-htg-impact-slide d-flex align-items-end flex-column" impact={impactTabs.multidisciplinary} />
                    </div>
                    <div>
                        <ImpactTabComponent className="gs-htg-impact-slide d-flex align-items-end flex-column" impact={impactTabs.twoWeek} />
                    </div>
                    <div>
                        <ImpactTabComponent className="gs-htg-impact-slide d-flex align-items-end flex-column" impact={impactTabs.ngo} />
                    </div>
                    <div>
                        <ImpactTabComponent className="gs-htg-impact-slide d-flex align-items-end flex-column" impact={impactTabs.mentorship} />
                    </div>
                    <div />
                    <div />
                </Slider>
            </Container>
        </div>
    );
};

export default ImpactComponent;
