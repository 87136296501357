import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    Row, Col, Container,
} from 'react-bootstrap';

import { HTG_IMAGE, EXTERNAL_LINKS } from '../util/constants';

const LandingComponent = () => (
    <Container
        data-aos="fade-right"
        fluid
        className="gs-block gs-landing"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${HTG_IMAGE.landing})` }}
    >
        <Container>
            <Row className="h-100 gs-landing-margin">
                <Col lg={3} className="pr-0" />
                <Col lg={9} className="text-right text-white text-image-alignment">
                    <h1 className="pb-2">
                        Canada's 1st multidisciplinary social impact hackathon.
                    </h1>
                    <p>
                        Tackle the world's most pressing development challenges
                        <br />
                        Kickoff: Feb 27th, 2021
                        <br />
                        Hackathon: Mar 13-14th, 2021
                        <br />
                    </p>
                    {/* <a className="btn" href={EXTERNAL_LINKS.HACKER_DASH} target="_blank" rel="noopener noreferrer">Apply <FontAwesomeIcon icon={faArrowRight} /></a> */}
                </Col>
            </Row>
        </Container>
    </Container>
);

export default LandingComponent;
