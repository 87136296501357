import React from 'react';
import {
    Col, Row, Container, Image,
} from 'react-bootstrap';

import { CURRICULUM_IMAGE } from '../util/constants';

const Graph1Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5">
            <h4>We bridge the gap between technical theory and global applications for 100+ courses</h4>
            <p>
                We exist because we think that education is in need of an update. Most university courses focus on theory, not practical applications.
                When we see these practical applications, far too many are grounded in only developed, urban contexts.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={CURRICULUM_IMAGE.graph1} fluid className="w-30" />
            </div>
            <p className="text-center">Average percentage of Canadian university courses that contain global applications as part of their curriculum</p>
        </Col>
    </Row>
);

const Graph2Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5 order-md-2">
            <h4>
                We want multidisciplinary and global thinking at the heart of
                <i>every</i>
                {' '}
                course
            </h4>
            <p>
                The curriculum team collaborates with university faculties to shift the way students explore the world’s most pressing issues and build the tools to tackle them.
                Our vision is to see global content and multidisciplinary thinking at the core of
                {' '}
                <i>every</i>
                {' '}
                course, with students ready to be the next generation of world-changers.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5 order-md-1">
            <h5 className="text-center">Course types engaged in 2020</h5>
            <div className="d-inline-block text-center">
                <Image src={CURRICULUM_IMAGE.graph2} fluid className="w-75" />
            </div>
        </Col>
    </Row>
);

const Graph3Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5">
            <h4>We bring global perspectives to students, starting in the classroom</h4>
            <p>
                We work directly with professors and institutional leaders to identify potential courses, build new content and deliver a thoughtful experience,
                through real-world case studies, engaging debates, idea-sparking discussions, and more. Since our inception, we’ve driven change across 100+ courses
                at 5 different universities.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <h5 className="text-center">Global Spark course integration by year of study</h5>
            <div className="d-inline-block text-center">
                <Image src={CURRICULUM_IMAGE.graph3} fluid className="w-75" />
            </div>
        </Col>
    </Row>
);

const Graph4Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5 order-md-2">
            <h4>We develop integrations that are relevant, meaningful and impactful across a wide range of courses</h4>
            <p>
                Our collaborations have empowered students to see the real-life applications of classroom knowledge across a broad set of courses—from
                electrical communications systems to engineering design to entrepreneurship to statistics. Through these partnerships, we’ve challenged
                students to think deeply about leveraging multidisciplinary thinking and delve into diverse perspectives.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5 order-md-1">
            <h5 className="text-center">Types of curriculums developed</h5>
            <div className="d-inline-block text-center">
                <Image src={CURRICULUM_IMAGE.graph4} fluid className="w-75" />
            </div>
        </Col>
    </Row>
);

const HowItWorksComponent = () => (
    <div>
        <Container fluid className="gs-block bg-white">
            <Container>
                <Row className="pb-4" data-aos="fade-up">
                    <Col>
                        <h3 className="text-center pb-3">Our Approach</h3>
                    </Col>
                </Row>
                <Graph1Component />
                <Graph2Component />
                <Graph3Component />
                <Graph4Component />
            </Container>
        </Container>
    </div>
);

export default HowItWorksComponent;
