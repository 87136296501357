import React from 'react';

import {
    Row, Col, Container,
} from 'react-bootstrap';

import { HTG_IMAGE } from '../util/constants';

const SampleProjectComponent = () => (
    <Container fluid className="gs-block">
        <Container>
            <h3 className="text-center pb-5">Winners from Hack the Globe 2020</h3>
            <Row data-aos="fade-up">
                <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                    <div
                        className="gs-htg-hack-box mb-3"
                        style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.winner})` }}
                    >
                        <div>
                            <p><b>1st Place</b></p>
                            <h4 className="pb-5">Connexus</h4>
                            <p>
                                This crisis response solution aimed to facilitate family reunification in crisis situations using image recognition technology.
                            </p>
                            <p>
                                Hui Wen Zheng, Jai Aggarwal, Martina Facchin, Mia Nguyen
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                    <div
                        className="gs-htg-hack-box mb-3"
                        style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.runnerup})` }}
                    >
                        <div>
                            <p><b>Runner Up</b></p>
                            <h4 className="pb-5">HemoGo!</h4>
                            <p>
                                This global health solution aimed to ensure a steady supply of blood donations through encouragement and donor retention.
                            </p>
                            <p>
                                Bayaan Shalaby, Henry Xu, Sarika Goel, Sheree Zhang
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                    <div
                        className="gs-htg-hack-box pb-3"
                        style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.peoplechoice})` }}
                    >
                        <div>
                            <p><b>People’s Choice</b></p>
                            <h4 className="pb-5">chickoo</h4>
                            <p>
                                This popular solution was an SMS platform that connected farmers in India to agricultural data specialized to their location and needs.
                            </p>
                            <p>
                                Anna Wang, Chi-Hong Cheung, Jennifer Ge, Noah Simao
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </Container>
);

export default SampleProjectComponent;
