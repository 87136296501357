import React from 'react';
import { Container } from 'react-bootstrap';
import { EXTERNAL_LINKS } from '../util/constants';

const Banner = () => (
    <div id="gs-htg-banner">
        <Container className="bg-dark-red text-white text-center p-3" fluid>
            <h3 className="m-0">
            Registration for the 2021 Global Thought Leader Panel is now open. Register
                {' '}
                <a href={EXTERNAL_LINKS.PANEL_REG} target="_blank" rel="noopener noreferrer">here</a>
                {' '}
                {/* Applications for Hack The Globe are now open!
                {' '}
                <a href={EXTERNAL_LINKS.HACKER_DASH} target="_blank" rel="noopener noreferrer">Apply</a>
                {' '}
                by Feb 7. */}
            </h3>
        </Container>
    </div>
);

export default Banner;
