import React from 'react';
import {
    Col, Row, Container, Image,
} from 'react-bootstrap';

import ApproachComponent from './ApproachComponent';
import ExampleComponent from './ExampleComponent';
import { CURRICULUM_IMAGE } from '../util/constants';

const HowItWorksComponent = () => (
    <div>
        <Container className="bg-blue gs-block text-white" fluid>
            <Container>
                <Row className="pb-4" data-aos="fade-up">
                    <Col lg={8}>
                        <h3 className="pb-3">How It Works</h3>
                        <p>
                            We collaborate with professors to create novel global content, designed to fit perfectly in their course.
                        </p>
                    </Col>
                </Row>
                <Row className="pb-3" data-aos="fade-up">
                    <Col md={9}>
                        <Row className="gs-curriculum-circle-img-pill d-flex align-items-center justify-content-center">
                            <Image src={CURRICULUM_IMAGE.bulb} className="gs-curriculum-circle-img" fluid roundedCircle />
                            <Col md={10}>
                                <h5>Ideation</h5>
                                <p>
                                    Local student campus teams connect with professors and course instructors to capture the needs and opportunities within a course.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-3" data-aos="fade-up">
                    <Col md={9}>
                        <Row className="gs-curriculum-circle-img-pill d-flex align-items-center justify-content-center">
                            <Image src={CURRICULUM_IMAGE.search} className="gs-curriculum-circle-img" fluid roundedCircle />
                            <Col md={10}>
                                <h5>Development</h5>
                                <p>
                                    Students develop materials that align with course objectives, while maintaining consistent communication with course instructors during iteration.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-3" data-aos="fade-up">
                    <Col md={9}>
                        <Row className="gs-curriculum-circle-img-pill d-flex align-items-center justify-content-center">
                            <Image src={CURRICULUM_IMAGE.screen} className="gs-curriculum-circle-img" fluid roundedCircle />
                            <Col md={10}>
                                <h5>Delivery</h5>
                                <p>
                                    Students and/or course coordinators deliver the content to classes, engaging them in taking their technical knowledge to global issues that matter.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    </div>
);

const BodyComponent = () => (
    <div id="curriculum-body">
        <ApproachComponent />
        <HowItWorksComponent />
        <ExampleComponent />
    </div>
);

export default BodyComponent;
