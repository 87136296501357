import React from 'react';
import {
    Row, Col, Image, Container, Button, Tabs, Tab,
} from 'react-bootstrap';

import { HTG_IMAGE, EXTERNAL_LINKS } from '../util/constants';
import SampleProjectComponent from './SampleProjectComponent';
import ImpactComponent from './ImpactComponent';
import FAQComponent from './FAQComponent';
import TestimonialsComponent from './TestimonialsComponent';

const DescriptionComponent = () => (
    <div id="gs-htg-desc">
        <Container className="gs-block">
            <Row>
                <Col md={5} data-aos="fade-right">
                    <h3 className="pb-4 text-dark-blue">Hack the Globe is not a traditional hackathon.</h3>
                </Col>
                <Col md={7} data-aos="fade-left">
                    <p>
                        Traditional hackathons attract engineering &amp; computer science students to spend 48 hours on complex technical prototypes
                        that often don’t adequately consider the social, political, and economic dimensions of the problems they attempt to fix.
                    </p>
                    <p>
                        We challenge all students to tackle humanity’s most pressing problems by adopting a multidisciplinary mindset and focusing
                        on sustainable outcomes. Over a two week period, Hack the Globe brings together the brightest and most passionate students
                        from across the globe to change the way they see the world in order to create innovative solutions that apply classroom
                        learnings to real-world applications.
                    </p>
                </Col>
            </Row>
        </Container>
        <Container className="gs-block gs-htg-impact">
            <h3 className="pb-4 text-dark-blue text-center">Impact-First Solutions</h3>
            <Row>
                <Col lg={5} className="d-none d-lg-block gs-htg-impact-add-img">
                    <Image className="pb-3 h-50" src={HTG_IMAGE.ngo} fluid />
                    <Image className="h-50" src={HTG_IMAGE.landing} fluid />
                </Col>
                <Col className="pl-lg-0">
                    <Image className="pb-3" src={HTG_IMAGE.htg_2020} fluid />
                    <p className="pt-lg-3 pl-lg-3 pr-lg-3">
                        Hack the Globe prioritizes solutions over software, features over frills, and practicality over pageantry.
                        We encourage students to narrow down onto the root of the challenges they are tackling in order to create
                        a fully holistic and implementable solution. There are four foundational pillars that enable our innovative
                        experience:
                    </p>
                </Col>
            </Row>
        </Container>
    </div>
);

const TimelineComponent = () => (
    <Container fluid className="bg-red gs-block">
        <Container>
            <h3 className="text-center pb-4">Hack the Globe 2021: The Ultimate Hackathon Experience</h3>
            <Row className="pb-3" data-aos="fade-up">
                <Col md={9}>
                    <Row className="gs-circle-img-pill d-flex align-items-center justify-content-center" style={{ backgroundColor: '#E48B7C' }}>
                        <Image src={HTG_IMAGE.shoes} className="gs-circle-img" fluid roundedCircle />
                        <Col md={10}>
                            <h5>Kickoff (Feb 27th)</h5>
                            <p>Engage directly with global experts and understand the social, technical, and business nuances of the problems you’ll be tackling.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pb-3" data-aos="fade-up">
                <Col md={1} />
                <Col md={9}>
                    <Row className="gs-circle-img-pill d-flex align-items-center justify-content-center" style={{ backgroundColor: '#E48B7C' }}>
                        <Image src={HTG_IMAGE.lightbulb} className="gs-circle-img" fluid roundedCircle />
                        <Col md={10}>
                            <h5>Ideation (Feb 28th - Mar 12th)</h5>
                            <p>Brainstorm ideas, research the problem space, and build a preliminary model of your solution with your BCG mentor.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pb-3" data-aos="fade-up">
                <Col md={2} />
                <Col md={9}>
                    <Row className="gs-circle-img-pill d-flex align-items-center justify-content-center" style={{ backgroundColor: '#E48B7C' }}>
                        <Image src={HTG_IMAGE.data} className="gs-circle-img" fluid roundedCircle />
                        <Col md={10}>
                            <h5>Hackathon (Mar 13th &amp; 14th)</h5>
                            <p>
                                Build a minimum viable product and business case to support your solution to a chosen theme. During the 24 hours,
                                workshops and office hours with industry experts are available to help guide the development of your final solution.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row data-aos="fade-up">
                <Col md={3} />
                <Col md={9}>
                    <Row className="gs-circle-img-pill d-flex align-items-center justify-content-center" style={{ backgroundColor: '#E48B7C' }}>
                        <Image src={HTG_IMAGE.hands} className="gs-circle-img" fluid roundedCircle />
                        <Col md={10}>
                            <h5>Entrepreneurship</h5>
                            <p>
                                The end of the hackathon is just the beginning of the journey for your solution. Winners will be offered workshops and connections to
                                incubators and startup accelerators to further develop your solution and bring you one step closer to lasting change.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </Container>
);

const SpeakerComponent = () => (
    <Container
        data-aos="fade-right"
        fluid
        id="gs-htg-speaker-bg"
        style={{ backgroundImage: `url(${HTG_IMAGE.htg_logo})` }}
    >
        <Container className="gs-block" id="gs-htg-speaker">
            <h3 className="text-center pb-3" data-aos="fade-up">
                <span className="text-dark-blue">2021</span>
                {' '}
                Keynote Speaker
            </h3>
            <Row>
                <Col md={4} className="text-center pb-3 text-md-right pl-lg-5 pr-lg-5" data-aos="fade-left">
                    <b>
                        <Image src={HTG_IMAGE.speaker} className="pb-3" fluid alt="Robert Opp" />
                        <br />
                        Robert Opp
                        {' '}
                        <br />
                        Chief Digital Officer
                        {' '}
                        <br />
                        UNDP
                    </b>
                </Col>
                <Col md={8} className="pr-md-5 pl-md-5" data-aos="fade-right">
                    <p>
                        Robert Opp is currently the Chief Digital Officer of United Nations Development Programme (UNDP), a development
                        organization working across 170 countries globally with more than 17,000 staff. He leads the agency’s digital
                        transformation, an organization-wide effort, to harness the power of new technology to improve the lives of
                        those furthest behind.
                    </p>
                    <p>
                        Prior to this role, Robert served as Director of the Innovation and Change Management Division within the United
                        Nations World Food Programme (WFP) where he established an in-house ‘Zero Hunger’ innovation accelerator and created
                        an award-winning crowdfunding smartphone app, ShareTheMeal which has raised over US$40 million for hunger programs.
                    </p>
                </Col>
            </Row>
        </Container>
    </Container>
);

const ChallengeThemesComponent = () => (
    <Container className="gs-block" id="gs-htg-themes">
        <h3 className="text-center">Challenge Themes for</h3>
        <h3 className="text-center text-dark-blue">Hack The Globe 2021</h3>

        <Row data-aos="fade-up" className="align-items-center p-3">
            <Col lg={6} className="text-center">
                <Image src={HTG_IMAGE.climate} alt="Challenge Theme" fluid />
            </Col>
            <Col lg={6} className="">
                <h4 className="pb-3 pt-3 text-dark-blue">Climate Change</h4>
                <p>
                    Prevent and mitigate the impacts of climate change through the development of green food production, waste
                    reduction, sustainable infrastructure, and reducing ecological footprints.
                </p>
            </Col>
        </Row>
        <Row data-aos="fade-up" className="align-items-center">
            <Col lg={6} className="order-lg-2 text-center">
                <Image src={HTG_IMAGE.economic} alt="Challenge Theme" fluid />
            </Col>
            <Col lg={6} className="order-lg-1 text-left text-lg-right">
                <h4 className="pb-3 pt-3 text-dark-blue">Economic Empowerment</h4>
                <p>
                    Enable and empower underrepresented populations to access education, increase economic opportunities, and
                    realize decent work for all.
                </p>
            </Col>
        </Row>
        <Row data-aos="fade-up" className="align-items-center">
            <Col lg={6} className="text-center">
                <Image src={HTG_IMAGE.health} alt="Challenge Theme" fluid />
            </Col>
            <Col lg={6}>
                <h4 className="pb-3 pt-3 text-dark-blue">Global Health</h4>
                <p>
                    Increase the access to and capacity of essential health services in underserved communities and better identify
                    global health threats by improving diagnostic measures and the quality of preventative action.
                </p>
            </Col>
        </Row>
        <Row data-aos="fade-up" className="align-items-center">
            <Col lg={6} className="order-lg-2 text-center">
                <Image src={HTG_IMAGE.society} alt="Challenge Theme" fluid />
            </Col>
            <Col lg={6} className="order-lg-1 text-left text-lg-right">
                <h4 className="pb-3 pt-3 text-dark-blue">Equitable Societies</h4>
                <p>
                    Support and accelerate movements to achieve equity and civil rights for marginalized communities across the world.
                </p>
            </Col>
        </Row>
    </Container>
);

const ScheduleComponent = () => (

    <Container className="gs-block">
        <h3 className="text-center text-dark-blue">Schedule</h3>
        <Tabs
            className="gs-tabs pt-2 pb-4"
            defaultActiveKey="0"
        >
            <Tab eventKey="0" title="Kickoff" className="slick-center">
                <Image src={HTG_IMAGE.schedule1} fluid />
            </Tab>
            <Tab eventKey="1" title="Hackathon Day 1" className="slick-center">
                <Image src={HTG_IMAGE.schedule2} fluid />
            </Tab>
            <Tab eventKey="2" title="Hackathon Day 2" className="slick-center">
                <Image src={HTG_IMAGE.schedule3} fluid />
            </Tab>
        </Tabs>
    </Container>
);

const CallToActionComponent = () => (
    <Container className="gs-block">
        <h3 className="text-center pb-3" data-aos="fade-up">Eager to Make a Difference?</h3>
        <Row className="pb-3" data-aos="fade-up">
            <Col md={1} />
            <Col md={10}>
                <p>
                    We are always in search of expanding our partner network for our participants. Hack the Globe will be your hub to connect
                    with the brightest and most passionate tech and business talent in Canada. You’ll have a chance to mentor and recruit
                    participants, judge solutions, host workshops, demo APIs and software, and much more.
                </p>
            </Col>
        </Row>
        <Row className="align-middle">
            <Col className="text-center" data-aos="fade-up">
                <Button href={`mailto: ${EXTERNAL_LINKS.EMAIL}`}>Contact Us</Button>
            </Col>
        </Row>
    </Container>
);

const BodyComponent = () => (
    <div id="htg-body">
        <div className="gs-htg-body-bg" style={{ backgroundImage: `url(${HTG_IMAGE.bg})` }}>
            <DescriptionComponent />
        </div>
        <ImpactComponent />
        <SpeakerComponent />
        <TimelineComponent />
        <ChallengeThemesComponent />
        <ScheduleComponent />
        <TestimonialsComponent />
        <SampleProjectComponent />
        <FAQComponent />
        <CallToActionComponent />
    </div>
);

export default BodyComponent;
