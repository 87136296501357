import { HTG_IMAGE } from '../util/constants';

export const impactTabs = {
    twoWeek: {
        title: 'Two-Week Timeline',
        desc: 'Unlike traditional weekend-only hackathons, Hack the Globe spans two weeks, enabling students to thoroughly research their problem area, brainstorm a variety of potential ideas, and refine their final solution. As a result, not only are the solutions more intelligent and sound, but the students are more engaged and equipped in sustainable entrepreneurship.',
        img: HTG_IMAGE.two_week,
    },
    multidisciplinary: {
        title: 'Multidisciplinary Teams',
        desc: 'Teams are composed of students from various programs and degrees, equipping each with the technical, business, and social awareness needed to deliver sustainable solutions. Through our multidisciplinary nature, we can stretch the confines in which students think about and approach problems in order to adopt a variety of perspectives.',
        img: HTG_IMAGE.multidisciplinary,
    },
    mentorship: {
        title: 'Direct BCG Mentorship',
        desc: 'Each team is provided a dedicated mentor from the Boston Consulting Group, guiding them through how a top global consultancy solves large-scale problems.',
        img: HTG_IMAGE.mentorship,
    },
    ngo: {
        title: 'Global NGO Partners',
        desc: 'We partner with global NGOs to provide students direct access to the latest thinking from experts who are tackling these same very challenges on a daily basis. As students listen to presentations, ask questions, and test their hypotheses, their solutions become more relevant and applicable to current conditions.',
        img: HTG_IMAGE.ngo,
    },
};

export const FAQs = [
    {
        Q: 'What is a Hackathon and why is this one different?',
        A: 'A hackathon is a 24 or 36-hour event where participants (hackers) come in and build a software or hardware project. Hack the Globe is more than just a hackathon; it begins with a Kickoff, marking the beginning of the ideation phase for hackers. During those two weeks, teams will work with a mentor from BCG to formulate a solution within one of six themes. Finally, teams will build a technical prototype and business pitch during the Hackathon and present to an expert panel of judges.',
    },
    {
        Q: 'When and where is Hack the Globe happening?',
        A: 'The main two events will be the Kickoff and the Hackathon weekend. The Kickoff will take place on February 29th, 2020, and the Hackathon will take place on March 14th-15th, 2020. Both events will take place at the Boston Consulting Group’s Toronto office in the Bay-Wellington Tower.',
    },
    {
        Q: 'How much does it cost to participate?',
        A: "Hack the Globe is free for participants! We'll also be providing meals at the event.",
    },
    {
        Q: 'Who can participate?',
        A: 'We are only accepting undergraduate and graduate university students.',
    },
    {
        Q: 'Do I need to apply in a team?',
        A: 'You can certainly apply solo and be accepted, and we’ll match you with other solo applicants closer to the event. Hack the Globe will be a team-based hackathon, so everyone will be formed into teams of 4 in time for the kickoff.',
    },
    {
        Q: 'What’s the deadline for applying?',
        A: 'The deadline to apply for Hack the Globe is February 9th, 2020.',
    },
    {
        Q: 'What if I don’t know how to code?',
        A: "That's no problem - Hack the Globe involves much more than just coding, and we'll have technical workshops and mentors on the day to help you learn. We encourage students from all backgrounds to apply! Hack the Globe is all about multidisciplinary approaches to problem solving and we look forward to welcoming a diverse pool of applicants and participants.",
    },
    {
        Q: 'Will there be travel accommodations or reimbursements?',
        A: 'Unfortunately, we will not be funding travel for participants this year. We apologize for any inconvenience this might cause.',
    },
    {
        Q: 'Will there be areas for me to sleep during the event?',
        A: 'Due to the spatial capacity of the venue, there will not be any designated sleeping areas, though you are welcome to stay at the venue overnight. We encourage any out-of-town participants to connect to local hackers who may be able to provide a place to stay for the night.',
    },
    {
        Q: 'What should I bring to the event?',
        A: 'The kickoff day will be business casual dress code, and you won’t need anything except perhaps a notepad and pen. For the hackathon, you’ll definitely need a laptop, charger, and a comfortable set of clothes!',
    },
];
