import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import AOS from 'aos';
import '../../node_modules/aos/dist/aos.css';

import { ROUTES } from '../util/constants';

import Footer from './Footer';
import Header from './Header';
import Banner from './Banner';

import { firebaseInit } from '../util/firebase';
import { reactGAInit } from '../util/analytics';

import HomeComponent from '../home/HomeComponent';
import HackTheGlobeComponent from '../htg/HTGComponent';
import PanelComponent from '../panel/PanelComponent';
import TeamComponent from '../team/TeamComponent';
import CurriculumComponent from '../curriculum/CurriculumComponent';
import LocalEventsComponent from '../localEvents/LocalEventsComponent';

const App = () => {
    AOS.init();
    firebaseInit(() => {});
    reactGAInit();

    return (
        <HashRouter basename={process.env.PUBLIC_URL}>
            <Header />
            <Banner />
            <div className="gs-content">
                <Switch>
                    <Route path={ROUTES.LANDING} exact component={HomeComponent} />
                    <Route path={ROUTES.HACK_THE_GLOBE} component={HackTheGlobeComponent} />
                    <Route path={ROUTES.PANEL} component={PanelComponent} />
                    <Route path={ROUTES.TEAM} component={TeamComponent} />
                    <Route path={ROUTES.CURRICULUM} component={CurriculumComponent} />
                    <Route path={ROUTES.LOCAL_EVENTS} component={LocalEventsComponent} />
                </Switch>
            </div>
            <Footer />
        </HashRouter>
    );
};

export default App;
