import React from 'react';
import {
    Row, Col, Container,
} from 'react-bootstrap';

import { EVENTS_IMAGE } from '../util/constants';

const LandingComponent = () => (
    <Container
        data-aos="fade-right"
        fluid
        className="gs-block gs-landing"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${EVENTS_IMAGE.landing})` }}
    >
        <Container>
            <Row className="h-100 gs-landing-margin">
                <Col lg={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} className="text-right text-white text-image-alignment">
                    <h1 className="pb-2 d-none d-sm-block">
                        Impactful events to foster the next generation of successful global problem solvers
                    </h1>
                    <h3 className="pb-2 d-sm-none">
                        Impactful events to foster the next generation of successful global problem solvers
                    </h3>
                    <p>
                        Partnering with student organizations and institutions, we organize hands-on learning opportunities
                        and events to change how students approach tackling global challenges.
                    </p>
                </Col>
            </Row>
        </Container>
    </Container>
);

export default LandingComponent;
