export const ROUTES = {
    LANDING: '/',
    HACK_THE_GLOBE: '/hacktheglobe',
    PANEL: '/thoughtleader',
    CURRICULUM: '/curriculum',
    LOCAL_EVENTS: '/localevents',
    TEAM: '/team',
};

export const EXTERNAL_LINKS = {
    FACEBOOK: 'https://www.facebook.com/globalspark.world',
    INSTAGRAM: 'https://www.instagram.com/global.spark',
    LINKEDIN: 'https://www.linkedin.com/company/global-spark-world/',
    TWITTER: 'https://twitter.com/geweek?lang=en',
    EMAIL: 'contactus@globalspark.world',
    HACKER_DASH: 'https://hack.globalspark.world',
    PANEL_REG: 'http://bit.ly/global_spark_panel',
};

export const SOCIAL_MEDIA_IMAGE = {
    facebook_logo_black: require('../assets/images/socialMediaIcons/facebook_black.svg'),
    instagram_logo_black: require('../assets/images/socialMediaIcons/instagram_black.svg'),
    linkedin_logo_black: require('../assets/images/socialMediaIcons/linkedin_black.svg'),
    twitter_logo_black: require('../assets/images/socialMediaIcons/twitter_black.svg'),
    facebook_logo_white: require('../assets/images/socialMediaIcons/facebook_white.svg'),
    instagram_logo_white: require('../assets/images/socialMediaIcons/instagram_white.svg'),
    linkedin_logo_white: require('../assets/images/socialMediaIcons/linkedin_white.svg'),
    twitter_logo_white: require('../assets/images/socialMediaIcons/twitter_white.svg'),
    logo: require('../assets/images/logo/bright-bg-long.png'),
    logo_short: require('../assets/images/logo/bright-bg-short.png'),
    footer_graphics: require('../assets/images/home/footer-graphics.svg'),
};

export const HOME_IMAGE = {
    curriculum: require('../assets/images/home/curriculum.jpg'),
    htg: require('../assets/images/home/htg.jpg'),
    local_events: require('../assets/images/home/local-events.jpg'),
    speaker_panel: require('../assets/images/home/speaker-panel.jpg'),
    logo: require('../assets/images/home/logo.png'),
    arrow_bl: require('../assets/images/home/arrow_bl.png'),
    arrow_br: require('../assets/images/home/arrow_br.png'),
    arrow_top: require('../assets/images/home/arrow_top.svg'),
    arrow_l: require('../assets/images/home/arrow_l.png'),
    arrow_r: require('../assets/images/home/arrow_r.png'),
    arrow_c: require('../assets/images/home/arrow_c.png'),
    bg: require('../assets/images/home/bg.svg'),
    body_bg: require('../assets/images/home/body-bg.svg'),
    landing: require('../assets/images/home/landing.svg'),
    bg_impact_b: require('../assets/images/home/bg-impact-b.svg'),
    bg_impact_t: require('../assets/images/home/bg-impact-t.svg'),
};

export const PEOPLE_IMAGE = {
    malik_ismail: require('../assets/images/people/Malik_Ismail.png'),
    morris_huang: require('../assets/images/people/Morris_Huang.png'),
    julian_ashworth: require('../assets/images/people/Julian_Ashworth.png'),
    catherine_glossop: require('../assets/images/people/Catherine_Glossop.png'),
    alyf_janmohamed: require('../assets/images/people/Alyf_Janmohamed.png'),
    julia_chae: require('../assets/images/people/Julia_Chae.png'),
    esther_yoo: require('../assets/images/people/Esther_Yoo.png'),
    samantha_unger: require('../assets/images/people/Samantha_Unger.png'),
    ellen_chen: require('../assets/images/people/Ellen_Chen.png'),
    dasha_pushkareva: require('../assets/images/people/Dasha_Pushkareva.png'),
    henry_zhang: require('../assets/images/people/Henry_Zhang.png'),
    phil_lu: require('../assets/images/people/Phil_Lu.png'),
    noah_simao: require('../assets/images/people/Noah_Simao.png'),
    huiwen_zheng: require('../assets/images/people/Hui Wen_Zheng.png'),
    logan_kieller: require('../assets/images/people/Logan_Kieller.png'),
    andy_jiang: require('../assets/images/people/Andy_Jiang.png'),
    aidan_grenville: require('../assets/images/people/Aidan_Grenville.png'),
    zane_shad: require('../assets/images/people/Zane_Shad.png'),
    mitchell_li: require('../assets/images/people/Mitchell_Li.png'),
    kieran_cyphus: require('../assets/images/people/Kieran_Cyphus.png'),
    jina_yazdanpanah: require('../assets/images/people/Jina_Yaz.png'),
    tina_nia: require('../assets/images/people/Tina_Nia.png'),
    connor_mclennan: require('../assets/images/people/Connor_McLennan.png'),
    felix_yan: require('../assets/images/people/Felix_Yan.png'),
    catherine_chen: require('../assets/images/people/Catherine_Chen.png'),
    ayesha_raza: require('../assets/images/people/Ayesha_Raza.png'),
    brendan_lai: require('../assets/images/people/Brendan_Lai.png'),
    mia_nguyen: require('../assets/images/people/Mia_Nguyen.png'),
    atharv_agrawal: require('../assets/images/people/Atharv_Agrawal.png'),
    kailin_hong: require('../assets/images/people/Kailin_Hong.png'),
    afnan_rahman: require('../assets/images/people/Afnan_Rahman.png'),
    tamara_biwott: require('../assets/images/people/Tamara_Biwott.png'),
    vyoma_fadia: require('../assets/images/people/Vyoma_Fadia.png'),
    nicolas_bottger: require('../assets/images/people/Nicolas_Bottger.png'),
    alex_mark: require('../assets/images/people/Alex_Mark.png'),
    henry_xu: require('../assets/images/people/Henry_Xu.png'),
    martina_facchin: require('../assets/images/people/Martina_Facchin.png'),
    sean_xie: require('../assets/images/people/Sean_Xie.png'),
    sarah_chun: require('../assets/images/people/Sarah_Chun.png'),
    ruchi_hamal: require('../assets/images/people/Ruchi_Hamal.png'),
    anna_heer: require('../assets/images/people/Anna_Heer.png'),
    akinori_kimura: require('../assets/images/people/Akinori_Kimura.png'),
    mimi_le: require('../assets/images/people/Mimi_Le.png'),
    min_namgung: require('../assets/images/people/Min_Namgung.png'),
    madison_wong: require('../assets/images/people/Madison_Wong.png'),
    maria_fraser: require('../assets/images/people/Maria_Fraser Semenoff.png'),
    iris_guo: require('../assets/images/people/Iris_Guo.png'),
    hailey_reynolds: require('../assets/images/people/Hailey_Reynolds.png'),
    sandra_yang: require('../assets/images/people/Sandra_Yang.png'),
    annie_zou: require('../assets/images/people/Annie_Zou.png'),
    elliot_lam: require('../assets/images/people/Elliot_Lam.png'),
    alvina_lin: require('../assets/images/people/Alvina_Lin.png'),
    henry_mcardle: require('../assets/images/people/Henry_McArdle.png'),
    darren_tan: require('../assets/images/people/Darren_Tan.png'),
    kitan_ademidun: require('../assets/images/people/Kitan_Ademidun.png'),
    mia_vanoirschot: require('../assets/images/people/Mia_Van Oirschot.png'),
    susan_chen: require('../assets/images/people/Susan_Chen.png'),
    chloe_shingay: require('../assets/images/people/Chloe_Shin-Gay.png'),
    karolina_xin: require('../assets/images/people/Karolina_Xin.png'),
    alexa_tumaneng: require('../assets/images/people/Alexa_Tumaneng.png'),
    selina_wan: require('../assets/images/people/Selina_Wan.png'),
    helen_lin: require('../assets/images/people/Helen_Lin.png'),
    zoie_hou: require('../assets/images/people/Zoie_Hou.png'),
    irene_lee: require('../assets/images/people/Irene_Lee.png'),
    shaahana_naufal: require('../assets/images/people/Shaahana_Naufal.png'),
    mymy_tran: require('../assets/images/people/Mymy_Tran.png'),
    ayla_orucevic: require('../assets/images/people/Ayla_Orucevic.png'),
    aashan_meiyappan: require('../assets/images/people/Aashan_Meiyappan.png'),
    yixin_tian: require('../assets/images/people/Yixin_Tian.png'),
    john_kim: require('../assets/images/people/John_Kim.png'),
    roya_rahnejat: require('../assets/images/people/Roya_Rahnejat.png'),
    leila_martin: require('../assets/images/people/Leila_Martin.png'),
    rachel_wong: require('../assets/images/people/Rachel_Wong.png'),
};

export const HTG_IMAGE = {
    landing: require('../assets/images/htg/landing.jpg'),
    bg: require('../assets/images/htg/bg.svg'),
    htg_2020: require('../assets/images/htg/htg-2020.jpg'),
    htg_design: require('../assets/images/htg/htg-design.jpg'),
    peoplechoice: require('../assets/images/htg/peoplechoice.jpg'),
    runnerup: require('../assets/images/htg/runnerup.jpg'),
    winner: require('../assets/images/htg/winner.jpg'),
    shoes: require('../assets/images/htg/shoes.png'),
    data: require('../assets/images/htg/data.png'),
    hands: require('../assets/images/htg/hands.png'),
    lightbulb: require('../assets/images/htg/lightbulb.png'),
    two_week: require('../assets/images/htg/two-week.jpg'),
    multidisciplinary: require('../assets/images/htg/multidisciplinary.jpg'),
    mentorship: require('../assets/images/htg/mentorship.jpg'),
    ngo: require('../assets/images/htg/ngo.jpg'),
    climate: require('../assets/images/htg/climate.svg'),
    economic: require('../assets/images/htg/economic.svg'),
    health: require('../assets/images/htg/health.svg'),
    society: require('../assets/images/htg/society.svg'),
    speaker: require('../assets/images/htg/speaker.jpg'),
    htg_logo: require('../assets/images/htg/htg-logo.svg'),
    testimonial1: require('../assets/images/htg/testimonial1.png'),
    testimonial2: require('../assets/images/htg/testimonial2.png'),
    testimonial3: require('../assets/images/htg/testimonial3.png'),
    schedule1: require('../assets/images/htg/schedule1.svg'),
    schedule2: require('../assets/images/htg/schedule2.svg'),
    schedule3: require('../assets/images/htg/schedule3.svg'),
};

export const SPEAKER_IMAGE = {
    landing: require('../assets/images/speaker/landing.jpg'),
    sarah: require('../assets/images/speaker/sarah.jpg'),
    marieme: require('../assets/images/speaker/marieme.jpg'),
    omar: require('../assets/images/speaker/omar.png'),
    joe: require('../assets/images/speaker/joe.png'),
    mark: require('../assets/images/speaker/mark.jpg'),
    carla: require('../assets/images/speaker/carla.png'),
    jessie: require('../assets/images/speaker/jessie.png'),
    laura: require('../assets/images/speaker/laura.png'),
    janice: require('../assets/images/speaker/janice.png'),
    tim: require('../assets/images/speaker/tim.png'),
    andrew: require('../assets/images/speaker/andrew.png'),
    maureen: require('../assets/images/speaker/maureen.png'),
    jim: require('../assets/images/speaker/jim.png'),
    natasha: require('../assets/images/speaker/natasha.png'),
};

export const EVENTS_IMAGE = {
    landing: require('../assets/images/events/landing.jpg'),
    design_competition: require('../assets/images/events/design-competition.jpg'),
    public_events: require('../assets/images/events/public-events.jpg'),
    speaker_series: require('../assets/images/events/speaker-series.jpg'),
};

export const CURRICULUM_IMAGE = {
    landing: require('../assets/images/curriculum/landing.jpg'),
    covid: require('../assets/images/curriculum/covid.jpeg'),
    climate: require('../assets/images/curriculum/climate.jpeg'),
    chemeng: require('../assets/images/curriculum/chemeng.jpeg'),
    electricity: require('../assets/images/curriculum/electricity.jpg'),
    vaccine: require('../assets/images/curriculum/vaccine.jpeg'),
    bulb: require('../assets/images/curriculum/bulb.png'),
    screen: require('../assets/images/curriculum/screen.png'),
    search: require('../assets/images/curriculum/search.png'),
    graph1: require('../assets/images/curriculum/graph1.svg'),
    graph2: require('../assets/images/curriculum/graph2.png'),
    graph3: require('../assets/images/curriculum/graph3.png'),
    graph4: require('../assets/images/curriculum/graph4.png'),
};

export const SPONSORS_IMAGE = {
    stc: require('../assets/images/sponsors/stc.png'),
    bmo: require('../assets/images/sponsors/bmo.png'),
    mars: require('../assets/images/sponsors/mars.png'),
    bcg: require('../assets/images/sponsors/bcg.png'),
    otpp: require('../assets/images/sponsors/otpp.png'),
    uw: require('../assets/images/sponsors/uw.png'),
    ewb: require('../assets/images/sponsors/ewb.png'),
    western: require('../assets/images/sponsors/western.png'),
    ibm: require('../assets/images/sponsors/ibm.png'),
    gates: require('../assets/images/sponsors/gates.png'),
    gu: require('../assets/images/sponsors/gu.png'),
    clearbanc: require('../assets/images/sponsors/clearbanc.png'),
    uber_eats: require('../assets/images/sponsors/uber-eats.png'),
    growing_north: require('../assets/images/sponsors/growing-north.jpg'),
    agakhan: require('../assets/images/sponsors/agakhan.png'),
    sea: require('../assets/images/sponsors/sea.png'),
    soc: require('../assets/images/sponsors/soc.png'),
    uoft: require('../assets/images/sponsors/uoft.png'),
    ubc: require('../assets/images/sponsors/ubc.png'),
    celo: require('../assets/images/sponsors/celo.png'),
    leap: require('../assets/images/sponsors/leap.png'),
    google: require('../assets/images/sponsors/google.png'),
    wwf: require('../assets/images/sponsors/wwf.png'),
    wise: require('../assets/images/sponsors/wise.png'),
    cfcf: require('../assets/images/sponsors/cfcf.png'),
    nsbe: require('../assets/images/sponsors/nsbe.png'),
    munk: require('../assets/images/sponsors/munk.png'),
    degree_consulting: require('../assets/images/sponsors/180.png'),
    yncn: require('../assets/images/sponsors/yncn.png'),
    ieee: require('../assets/images/sponsors/ieee.png'),
    blueprint: require('../assets/images/sponsors/blueprint.png'),
    uwpm: require('../assets/images/sponsors/uwpm.png'),
    pbsn: require('../assets/images/sponsors/pbsn.png'),
    wecca: require('../assets/images/sponsors/wecca.png'),
    wds: require('../assets/images/sponsors/wds.png'),
    wics: require('../assets/images/sponsors/wics.png'),
    uwimpact: require('../assets/images/sponsors/uwimpact.png'),
    uwfossil: require('../assets/images/sponsors/uwfossil.png'),
    uwclimate: require('../assets/images/sponsors/uwclimate.png'),
    sci: require('../assets/images/sponsors/sci.png'),
    bag: require('../assets/images/sponsors/bag.png'),
    bcgcfca: require('../assets/images/sponsors/bcgcfca.jpg'),
    enactus: require('../assets/images/sponsors/enactus.png'),
    uweng: require('../assets/images/sponsors/uweng.png'),
    wfp: require('../assets/images/sponsors/wfp.png'),
    hfh_uw: require('../assets/images/sponsors/hfh-uw.png'),
    gcc: require('../assets/images/sponsors/gcc.svg'),
    mcmaster: require('../assets/images/sponsors/mcmaster.png'),
    td: require('../assets/images/sponsors/td.png'),
    flipp: require('../assets/images/sponsors/flipp.png'),
    darwin: require('../assets/images/sponsors/darwin.png'),
    ripple: require('../assets/images/sponsors/ripple.png'),
    voiceflow: require('../assets/images/sponsors/voiceflow.png'),
    thinkdata: require('../assets/images/sponsors/thinkdata.png'),
    cyclica: require('../assets/images/sponsors/cyclica.png'),
    united: require('../assets/images/sponsors/united.jpg'),
    rumie: require('../assets/images/sponsors/rumie.png'),
    bcch: require('../assets/images/sponsors/bcch.jpg'),
    global_medic: require('../assets/images/sponsors/global-medic.png'),
    nem: require('../assets/images/sponsors/nem.png'),
    mastercard: require('../assets/images/sponsors/mastercard.jpg'),
    grameen: require('../assets/images/sponsors/grameen.png'),
    loi: require('../assets/images/sponsors/loi.png'),
    gp: require('../assets/images/sponsors/gp.jpg'),
    ef: require('../assets/images/sponsors/ef.png'),
};
