import React, { useState } from 'react';
import {
    Tab, Tabs, Modal, Container,
} from 'react-bootstrap';
import Slider from 'react-slick';

import { CURRICULUM_IMAGE } from '../util/constants';

export const ExampleTabComponent = (props) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div data-aos="fade-up">
            <div
                className="gs-curriculum-example-slide d-flex align-items-end flex-column"
                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${props.img})` }}
            >
                <div>
                    <p className="pb-2">{ props.title }</p>
                    <h4 onClick={() => setShowModal(true)} className="hover-underline">
                        { props.subtitle }
                    </h4>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>{ props.title }</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{ props.desc }</Modal.Body>
            </Modal>
        </div>
    );
};

const ExampleComponent = () => {
    const [sliderObj, setSlider] = useState(0);
    const [key, setKey] = useState(0);
    const settings = {
        speed: 200,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
        beforeChange: (current, next) => { setKey(next); },
    };

    return (
        <div>
            <Container fluid className="bg-light-blue gs-block pl-0 pr-0">
                <h3 className="text-center text-dark-Blue pb-3" data-aos="fade-up">Examples of Our Curriculum Development</h3>
                <p className="text-center text-black" data-aos="fade-up">Browse through highlights of some of our past successful course integrations</p>
                <Tabs
                    className="gs-schedule-tabs pt-2 pb-4"
                    defaultActiveKey="0"
                    activeKey={key}
                    onSelect={(k) => sliderObj.slickGoTo(k)}
                    data-aos="fade-up"
                >
                    <Tab eventKey="0" title="COVID-19">
                        <div className="d-lg-none">
                            <ExampleTabComponent
                                desc="20 minutes, two speakers, and an engaging in-class discussion. Students dove into conversations about how
                                COVID-19 affects far more than just health. With small groups, students considered how the pandemic highlights
                                inequality and our responses can marginalize certain groups—with added emphasis on issues beyond just North America."
                                title="Engineering & Social Justice"
                                subtitle="COVID-19’s Impact on the World"
                                img={CURRICULUM_IMAGE.covid}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="1" title="Vaccine Distribution">
                        <div className="d-lg-none">
                            <ExampleTabComponent
                                desc="50 minutes, a lecture presentation and an applied class challenge. Students learned about the challenges
                                associated with delivering vaccines to the most remote areas worldwide. Understanding how their work can matter,
                                they designed algorithms to find the closest vaccine outputs for a given person—using social considerations while
                                applying technical knowledge."
                                title="Computer Programming"
                                subtitle="Building Vaccine Distribution Systems in Africa"
                                img={CURRICULUM_IMAGE.vaccine}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="2" title="Climate Change">
                        <div className="d-lg-none">
                            <ExampleTabComponent
                                desc="1 hour, an interactive lab activity, and a set of meaningful questions. Students explored how Internet of
                                Things technologies can help us tackle climate change monitoring. Guided by detailed lab instructions, students
                                built temperature-sensing circuits and considered what non-technical aspects would also be necessary for widespread
                                implementation—inviting discourse on global responses to critical issues."
                                title="Electrical Engineering"
                                subtitle="Connecting Op-amps and Climate Change Monitoring"
                                img={CURRICULUM_IMAGE.climate}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="3" title="Renewable Energy">
                        <div className="d-lg-none">
                            <ExampleTabComponent
                                desc="70 minutes, a midterm question, and a real-world case study. Students explored various aspects of engineering
                                in the context of different models of electricity generation, considering the scope of the problem, stakeholders,
                                and potential research questions as it pertains to a global frame of reference."
                                title="Engineering Design"
                                subtitle="Renewable Energy Alternatives in Zambia"
                                img={CURRICULUM_IMAGE.electricity}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="4" title="Lead Contamination">
                        <div className="d-lg-none">
                            <ExampleTabComponent
                                desc="50 minutes, a lecture presentation, and a worksheet with guiding questions. Students were first exposed to the
                                relevant background behind two pertinent examples of the modern water crisis, then worked in groups to formulate potential
                                solutions led by a set of thought-provoking prompts. Through the activity, students learned first-hand how to apply
                                classroom knowledge flexibly depending on the situation, and made direct comparisons in their approach to tackling the
                                same problem in different global contexts."
                                title="Chemical Engineering"
                                subtitle="Lead contamination— a comparison: Michigan vs India"
                                img={CURRICULUM_IMAGE.chemeng}
                            />
                        </div>
                    </Tab>
                </Tabs>
                <Slider {...settings} ref={(slider) => (setSlider(slider))} className="gs-curriculum-example-slider d-none d-lg-block">
                    <div>
                        <ExampleTabComponent
                            desc="20 minutes, two speakers, and an engaging in-class discussion. Students dove into conversations about how
                            COVID-19 affects far more than just health. With small groups, students considered how the pandemic highlights
                            inequality and our responses can marginalize certain groups—with added emphasis on issues beyond just North America."
                            title="Engineering & Social Justice"
                            subtitle="COVID-19’s Impact on the World"
                            img={CURRICULUM_IMAGE.covid}
                        />
                    </div>
                    <div>
                        <ExampleTabComponent
                            desc="50 minutes, a lecture presentation and an applied class challenge. Students learned about the challenges
                                associated with delivering vaccines to the most remote areas worldwide. Understanding how their work can matter,
                                they designed algorithms to find the closest vaccine outputs for a given person—using social considerations while
                                applying technical knowledge."
                            title="Computer Programming"
                            subtitle="Building Vaccine Distribution Systems in Africa"
                            img={CURRICULUM_IMAGE.vaccine}
                        />
                    </div>
                    <div>
                        <ExampleTabComponent
                            desc="1 hour, an interactive lab activity, and a set of meaningful questions. Students explored how Internet of
                                Things technologies can help us tackle climate change monitoring. Guided by detailed lab instructions, students
                                built temperature-sensing circuits and considered what non-technical aspects would also be necessary for widespread
                                implementation—inviting discourse on global responses to critical issues."
                            title="Electrical Engineering"
                            subtitle="Connecting Op-amps and Climate Change Monitoring"
                            img={CURRICULUM_IMAGE.climate}
                        />
                    </div>
                    <div>
                        <ExampleTabComponent
                            desc="70 minutes, a midterm question, and a real-world case study. Students explored various aspects of engineering
                                in the context of different models of electricity generation, considering the scope of the problem, stakeholders,
                                and potential research questions as it pertains to a global frame of reference."
                            title="Engineering Design"
                            subtitle="Renewable Energy Alternatives in Zambia"
                            img={CURRICULUM_IMAGE.electricity}
                        />
                    </div>
                    <div>
                        <ExampleTabComponent
                            desc="50 minutes, a lecture presentation, and a worksheet with guiding questions. Students were first exposed to the
                                relevant background behind two pertinent examples of the modern water crisis, then worked in groups to formulate potential
                                solutions led by a set of thought-provoking prompts. Through the activity, students learned first-hand how to apply
                                classroom knowledge flexibly depending on the situation, and made direct comparisons in their approach to tackling the
                                same problem in different global contexts."
                            title="Chemical Engineering"
                            subtitle="Lead contamination— a comparison: Michigan vs India"
                            img={CURRICULUM_IMAGE.chemeng}
                        />
                    </div>
                    <div />
                    <div />
                </Slider>
            </Container>
        </div>
    );
};

export default ExampleComponent;
