import React from 'react';
import { SPEAKER_IMAGE } from '../util/constants';

// Speakers constants
export const speakers2021 = [
    {
        img: SPEAKER_IMAGE.andrew,
        type: "SPEAKER",
        name: "Dr. Andrew Trister",
        title: <>
            Head of Digital Health Innovation,
            {' '}
            <b>The Bill and Melinda Gates Foundation</b>
            <br />
            Former Head of Machine Learning and Research for Health Special Projects,
            {' '}
            <b>Apple</b>
        </>,
        desc: <>
            Andrew Trister leads digital health innovation at Bill &amp; Melinda Gates Foundation. His team’s focus is to leverage 
            integrated mobile technology and data systems to aid in the appropriate use and process of performing diagnostics as 
            well as to enable high quality healthcare and wellness solutions in resource limited settings. The team leverages advanced 
            technologies and data science built around mobile devices as an interface to advance the goals of the foundation through 
            internal and external partnerships.
            <br />
            <br />
            Andrew is a physician scientist passionate about leveraging technology to improve health care for all. He believes the 
            combination of multiscale digital data from patients can impact our understanding, prevention and treatment of disease. 
            He joined the foundation from Apple, where he led clinical research and machine learning efforts in health for the special 
            projects team. Prior to Apple, he was the senior physician at Sage Bionetworks, where he focused on incentives for 
            researchers and participants to collaborate on large datasets and translating those findings to clinical settings. 
            <br />
            <br />
            Andrew completed residency in radiation oncology at the University of Washington, earned an M.D., Ph.D. in bioengineering, 
            MSE and BSE in computer science, and a B.A. in biological basis of behavior all from the University of Pennsylvania.
        </>
    },
    {
        img: SPEAKER_IMAGE.jim,
        type: "SPEAKER",
        name: "Jim Larson",
        title: <>
            Managing Director and Partner,
            {' '}
            <b>BCG</b>
            <br />
            Global Health and Social Impact Expert
        </>,
        desc: <>
            Jim Larson is a Managing Director and Partner at BCG, and is a core member of BCG’s Health Care and Social Impact practices. 
            Jim also leads the firm’s partnership with the Bill and Melinda Gates Foundation. Jim has spent time in BCG’s Washington DC, 
            Mumbai, and Seattle offices, primarily working in health care and global public health. He has also led a number of related 
            efforts engaging the private sector on global health topics, and is particularly active in the driving impact of digital in 
            global health. In his role leading the firm’s work with the Gates Foundation, Jim supports strategy development and execution 
            for the foundation and their grantees. He has worked with the foundation for more than ten years, and has contributed to more 
            than 60 projects for nearly all of their global program teams. Before joining BCG, Jim focused on economic and health policy 
            at Harvard University.
        </>
    },
    {
        img: SPEAKER_IMAGE.maureen,
        type: "SPEAKER",
        name: "Maureen Lewis ",
        title: <>
            CEO &amp; Founder,
            {' '}
            <b>Aceso Global</b>
            <br />
            Former Chief Economist,
            {' '}
            <b>The World Bank</b>
        </>,
        desc: <>
            Maureen Lewis has spent over 25 years advising government and private sector leaders around the world on health system reforms. 
            Dr. Lewis has held a variety of management and leadership positions in global health, including Chief Economist for Human Development 
            at the World Bank. She is the author of more than 75 research publications and working papers and five books, and is a member of 
            numerous international organizations including the Council on Foreign Relations  Dr. Lewis speaks frequently for private, public 
            and nonprofit audiences across the globe. She has a PhD in Economics from Johns Hopkins University.
        </>
    },
    {
        img: SPEAKER_IMAGE.natasha,
        type: "MODERATOR",
        name: "Natasha Walji",
        title: <>
            Director of Telecom, Tech and Public Sector,
            {' '}
            <b>Google Canada</b>
            <br />
            Recognized as one of Canada’s
            {' '}
            <b>Top 100 Most Powerful Women</b> by WXN
        </>,
        desc: <>
            Natasha Walji is a Director at Google Canada leading the Telecom, Tech and Public Sector industries. Previously, she was a McKinsey 
            consultant and a software developer. Natasha has actively served the community for ~20+ years and currently serves on the Board of 
            Ronald McDonald House Canada. She holds a BSc, Computer Science (UVic), MBA (Yale) and MSt. Sustainability Leadership (Cambridge). 
            Natasha is one of Canada's Top 100 Most Powerful Women (WXN).
        </>
    }
];

export const speakers = [
    {
        Name: <>
            Senior Partner & Senior Advisor,
            {' '}
            <b>BCG</b>
        </>,
        Title: 'Sarah Cairns-Smith',
        Desc: 'Sarah Cairns-Smith is a Senior Advisor for the Boston Consulting Group, she also acts as an independent advisor to foundations and non-profit organizations. In 2019 Sarah was a fellow in Harvard’s Advanced Leadership Initiative. She was formerly a Senior Partner and Managing Director of The Boston Consulting Group, retiring in June 2018. Sarah was a member of BCG’s global social impact, health care and people and organization leadership teams. She led BCGs Scientist Network, a global team of over 500 PhD and MDs. Sarah has led a variety of strategic and organizational assignments in the public, private and social sectors across the globe. She has experience driving multiple foundation efforts on topics as diverse as social justice/human rights, global health and development and climate change. Clients included the Bill & Melinda Gates Foundation, Ford Foundation and the United Nations. She has also worked with the private sector specializing in innovation and R&D with a focus in biopharma. She also helped form an industry/NIH consortium – the Accelerating Medicines Partnership.Her TED talk covered perspectives on digital innovation and global development: https://www.ted.com/watch/ted-institute/ted-bcg/sarah-cairns-smith-finally-tech-solutions-to-economic-development. In 2019, Sarah was a fellow in Harvard’s Advanced Leadership Initiative.Sarah chairs the board of the Amyloidosis Research Consortium and is on the board of Engender Health. Sarah received her PhD in Biochemistry from the Imperial Cancer Research Fund, London and did post-doctoral research at the Center for Neurobiology, Columbia University. Sarah completed the MBA program at Columbia Business School (Distinction).',
        Img: SPEAKER_IMAGE.sarah,
        Type: 'SPEAKER',
    },
    {
        Name: <>
            SVP,
            {' '}
            <b>ElementAI</b>
        </>,
        Title: 'Omar Dhalla',
        Desc: 'As SVP Solutions, Strategy and Corporate Development, Omar is focused on delivering value to enterprise partners while establishing the companys global market presence. At Real Ventures, he established the fund’s Ontario ecosystem operations and led deals in artificial intelligence, internet-of-things, and financial technology. Omar started his career with Accenture and McKinsey, then founded and operated early-stage ventures in both the packaged goods and media industries. He holds an MBA from the Richard Ivey School of Business at Western Ontario and a B.Comm (Finance) from the University of British Columbia.',
        Img: SPEAKER_IMAGE.omar,
        Type: 'SPEAKER',
    },
    {
        Name: <>Fintech and Digital Economy Innovation Expert</>,
        Title: 'Mark Wensley',
        Desc: 'Mark Wensley has over 15 years experience at the intersection of finance, technology and impact in emerging markets. He is currently a consultant to a major donor on the development of their fintech strategy for Africa and an adviser to several seed-stage fintech companies. He previously built and led the growth of the Mastercard Foundation’s fintech initiative and managed its smallholder farmer finance portfolio, which collectively deployed $300 million to serve over 12 million people in Africa. These initiatives address the critical financial needs of low-income clients and small businesses. He previously sourced startup finance companies across Asia for Unitus, a pioneering impact investor that accelerated their growth with equity investments and advisory. He is energized by building mission-driven organizations that tackle some of the world’s biggest challenges. He holds a Masters in Economics of Development from the Australian National University and a Bachelor of Commerce from McGill University.',
        Img: SPEAKER_IMAGE.mark,
        Type: 'SPEAKER',
    },
    {
        Name: <>
            Professor of Innovation,
            {' '}
            <b>Munk School of Global Affairs &amp; Public Policy</b>
        </>,
        Title: 'Joseph Wong',
        Desc: 'Joseph Wong is the Ralph and Roz Halbert Professor of Innovation at the Munk School of Global Affairs & Public Policy, and Professor of Political Science. He was the Director of the Asian Institute at the Munk School from 2005 to 2014. Wong is the author of many academic articles and several books, including Healthy Democracies: Welfare Politics In Taiwan and South Korea and Betting on Biotech: Innovation and the Limits of Asia’s Developmental State, both published by Cornell University Press. He is the co-editor, with Edward Friedman, of Political Transitions in Dominant Party Systems: Learning to Lose, published by Routledge, and Wong recently co-edited with Dilip Soman and Janice Stein Innovating for the Global South with the University of Toronto Press. Wong’s articles have appeared in journals such as Perspectives on Politics, Comparative Political Studies, Politics and Society, Governance, among many others. Professor Wong has been a visiting scholar at institutions in the US (Harvard), Taiwan, Korea, and the UK (Oxford); has worked extensively with the World Bank and the UN; and has advised governments on matters of public policy in Asia, Africa, the Americas, and Europe. Wong’s current research focuses on poverty and innovation. He is also working with Professor Dan Slater (Chicago) on a book about Asia’s development and democracy, currently under contract with Princeton University Press. Professor Wong teaches courses in the department of Political Science, the Munk One program and the Munk School of Global Affairs & Public Policy. Wong was educated at McGill and the University of Wisconsin-Madison.',
        Img: SPEAKER_IMAGE.joe,
        Type: 'SPEAKER',
    },
    {
        Name: <>
            Associate Director,
            {' '}
            <b>U of T School of Cities</b>
        </>,
        Title: 'Marieme Lo',
        Desc: 'Professor Marieme Lo is the Director of the African Studies Program and Associate Professor in Women and Gender Studies. She has a Licence from Paris 1, Panthéon-Sorbonne, a MA from the University of Dakar (Senegal) and MSc and PhD from Cornell University. She has also held fellowships at the University of Oxford and Georgetown University. Professor Lo’s work encompasses the political economy and creative dynamism of African urban informal economies, migration studies, female entrepreneurship, economic justice and rights to livelihood, with a focus on the dynamics of urban transformation, urban governance, resilience, urban poverty and inclusive urbanism, particularly in post-colonial cities. Her SSHRC funded research project, ‘Entrepreneurial Nomadism’, explores and maps women entrepreneurs’ transnational circuits and trade mobility in cities across three continents. Professor Lo is also engaged in collaboration with grassroots women’s organizations, civil society networks such as the West Africa Civil Society, and international organizations such as UN-Women, the World Food Programme, and the United Nations Development Programme (UNDP).',
        Img: SPEAKER_IMAGE.marieme,
        Type: 'MODERATOR',
    },
];
