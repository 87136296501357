import React from 'react';
import {
    Col, Row, Accordion, Container,
} from 'react-bootstrap';

import { EXTERNAL_LINKS } from '../util/constants';

const FAQComponent = () => (
    <Container id="gs-htg-faq" fluid data-aos="fade-up">
        <Container className="gs-block" data-aos="fade-up">
            <h3 className="text-center pb-3">Frequently Asked Questions</h3>
            <Row>
                <Col md={6}>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What is a Hackathon and why is this one different?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    A hackathon is typically a 24 - 48 hour event where participants (hackers) come in and build a software
                                    or hardware project.
                                </p>
                                <p>
                                    Hack the Globe is more than just a hackathon; it begins with a Kickoff, marking the beginning of the
                                    ideation phase for hackers. During those two weeks, teams will work with a mentor from BCG to formulate
                                    a solution within one of this year’s themes. Finally, teams will build a technical prototype and business
                                    pitch during the Hackathon and present to an expert panel of judges.
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Will the event be virtual this year? What can I expect from the workshops, activities, and communications?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    Hack the Globe 2021 will be a fully virtual event. All of your interactions with our keynote speakers,
                                    topic experts, BCG mentors, sponsors, and judges will be fully virtual.
                                </p>
                                <p>
                                    In 2020, Hack the Globe was one of Canada’s first hackathons to transition to be fully virtual during
                                    the onset of COVID-19 in March. Our team successfully pivoted to be fully virtual - incorporating
                                    virtual team challenges &amp; additional prizes - and we’re excited to share the new programming we have
                                    in store for 2021.
                                </p>
                                <p>
                                    Once applied, please check your email &amp; hacker dashboard for more information about application status
                                    and event logistics.
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Is there any cost to apply or attend?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                There is no cost for participants to apply or attend Hack the Globe.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What are the timelines for applications?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                Applications open on January 11th at 9:00am ET and will close on Feb 7st at 11:59PM ET.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What are the requirements to apply?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    Hack the Globe is open to all university students (Undergrads, Masters, PhDs, Diplomas, etc) from any
                                    discipline. In fact, last year nearly 40% of hackers were non-STEM with representation from every year of studies.
                                </p>
                                <p>
                                    Please submit an application either individually or as a team
                                    {' '}
                                    <a href={EXTERNAL_LINKS.HACKER_DASH} target="_blank" rel="noopener noreferrer">here</a>
                                    .
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                How can I prepare before the hackathon begins?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                While there is no mandatory preparation before the event, we will share resources on each challenge
                                theme in advance of the Kickoff. We highly encourage your team to review this material and attend
                                the Kickoff prepared with questions to ask with our topic experts and your BCG mentor.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                </Col>
                <Col md={6}>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What if I’ve never been to a hackathon before or if I don’t know how to code?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    There is no requirement to have previous hackathon experience - in fact, hackathons are a great place to
                                    learn a lot in a short amount of time!
                                </p>
                                <p>
                                    Hack the Globe is all about multidisciplinary approaches to problem solving and we accept a diverse pool
                                    of participants so there is no requirement to know how to code - coding is just one aspect of the event
                                    and successful teams will have members with business, political, and social expertise.
                                </p>
                                <p>
                                    Additionally, we offer a number of workshops during the hackathon weekend that cover technical implementation,
                                    non-profit strategy, presentation skills, and many other topics to round out your team. We encourage students
                                    from all backgrounds to apply!
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Can I participate if I’m not in Eastern Standard Time?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    Yes! We welcome participants from all across the globe to participate. We will try our best to accommodate
                                    the event and mentor chats to your time zone, however the preliminary, high-level schedule has already been
                                    set and can be found in the FAQs.
                                </p>
                                <p>
                                    If you applied as an individual, we will try our best to match you with a team from a similar time zone to
                                    enable your team to better collaborate.
                                </p>
                                <p>
                                    We recognize that some teams may have their presentation time in the middle of the night or early in the morning.
                                    For those teams, we will provide the option to submit a pre-recorded presentation with a list of prepared questions
                                    for your team to respond to. We will evaluate this on a case-by-case basis - we encourage you to apply and we
                                    will find a way to accommodate once accepted.
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What if I’m interested but can’t find a full team?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                You can apply as an individual and if accepted, we will match you with other individual applications closer to the
                                Kickoff date. Hack the Globe is  team-based hackathon, so everyone will be formed into teams of 4 and we will do our
                                best to match you with a diverse team of skill sets, capabilities, and experience.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What is the format of the final presentation?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                At the end of the 24-hour hacking period, each team will deliver a business pitch (typically done
                                via Powerpoint), a short demo of their technical prototype, and answer Q&amp;A from a panel of judges.
                                Successful teams will continue onto the final round of judging with a new panel of top-tier judges.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Can my team work on our solution during the 2 week ideation period?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                We encourage teams to use this 2 week time to think through the themes and problem areas that
                                they would like to tackle. You are free to do research, refine your scope, and brainstorm
                                with your BCG mentor during this time, however you will not be allowed to begin any work on
                                the presentation materials or code itself.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                </Col>
            </Row>
            <p>
                Any other questions? Don’t hesitate to contact us at
                {' '}
                <a href="mailto: hacktheglobe@globalspark.world">hacktheglobe@globalspark.world</a>
                !
            </p>
        </Container>
    </Container>
);

export default FAQComponent;
