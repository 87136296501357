import React from 'react';
import {
    Row, Col, Button, Container, Image,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import {
    HOME_IMAGE, ROUTES, EXTERNAL_LINKS,
} from '../util/constants';

const DescriptionComponent = () => (
    <div>
        <Container className="gs-block">
            <Row>
                <Col md={5} data-aos="fade-right">
                    <h3 className="text-center pb-3 text-dark-blue">What We Do</h3>
                </Col>
                <Col md={7} data-aos="fade-left">
                    <p>
                        <b>We have redesigned how ~15,000 students learn about and engage with our world’s toughest problems.</b>
                    </p>
                    <p>
                        As an education non-profit run by young professionals and students, our mission is to prepare
                        the next generation of leaders to solve our planet’s most complex problems.
                    </p>
                    <p>
                        We know that universities provide the foundation for how the next generation thinks about and
                        chooses their careers.
                        {' '}
                        <b>However, today there is a disconnect.</b>
                        {' '}
                        In the classroom, students are
                        primarily exposed to applications in developed, urban contexts, creating the misconception that
                        global development and their careers are divergent.
                    </p>
                    <p>
                        We aim to bridge this gap by providing in-classroom and practical opportunities to train the next
                        generation to use their technical skill sets to tackle pressing global challenges. Through
                        partnering with global universities and leading organizations, our innovative learning experiences
                        have three objectives:
                    </p>
                </Col>
            </Row>
        </Container>
    </div>
);

const ObjectivesComponent = () => (
    <Container fluid className="gs-block bg-dark-blue text-white gs-home-objectives">
        <Container className="d-block d-md-none" data-aos="fade-up">
            <h3 className="text-center pb-3">Our Objectives</h3>
            <Row className="gs-home-objectives-mobile align-items-center">
                <Col xs={2} className="p-0 pb-2 pb-sm-0 pr-sm-3 text-right align-self-center">
                    <Image className="gs-home-objectives-mobile-arrow" src={HOME_IMAGE.arrow_l} fluid />
                </Col>
                <Col className="text-center p-0 align-self-center">
                    <p>
                        <span>Challenge</span>
                        {' '}
                        students to learn about pressing global problems, how their skill sets can help tackle them,
                        and the benefits of multidisciplinary problem solving.
                    </p>
                    <Image src={HOME_IMAGE.arrow_c} fluid />
                    <p>
                        <span>Unleash</span>
                        {' '}
                        students by providing them with opportunities to harness their technical skill sets and
                        creativity to build cutting edge solutions with the support needed to make a difference.
                    </p>
                    <Image src={HOME_IMAGE.arrow_c} fluid />
                    <p>
                        <span>Amplify</span>
                        {' '}
                        students’ access to global topic experts and exposure to leading solutions to enable
                        discussions on the nuances of change.
                    </p>
                </Col>
                <Col xs={2} className="p-0 pt-2 pt-sm-0 pl-sm-3 align-self-center">
                    <Image className="gs-home-objectives-mobile-arrow" src={HOME_IMAGE.arrow_r} fluid />
                </Col>
            </Row>
        </Container>
        <Container className="d-none d-md-block">
            <Row className="align-items-center">
                <Col md={4}>
                    <p>
                        <span>Challenge</span>
                        {' '}
                        students to learn about pressing global problems, how their skill sets can help tackle them,
                        and the benefits of multidisciplinary problem solving.
                    </p>
                </Col>
                <Col md={4}>
                    <Image src={HOME_IMAGE.arrow_top} fluid />
                </Col>
                <Col md={4}>
                    <p>
                        <span>Unleash</span>
                        {' '}
                        students by providing them with opportunities to harness their technical skill sets and
                        creativity to build cutting-edge solutions with the support needed to make a difference.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Image src={HOME_IMAGE.arrow_bl} fluid />
                </Col>
                <Col md={4}>
                    <h3 className="pt-lg-5 text-center">Our Objectives</h3>
                    <p className="position-absolute b-0 pb-xl-5">
                        <span>Amplify</span>
                        {' '}
                        students’ access to global topic experts and exposure to leading solutions to enable
                        discussions on the nuances of change.
                    </p>
                </Col>
                <Col md={4}>
                    <Image src={HOME_IMAGE.arrow_br} fluid />
                </Col>
            </Row>
        </Container>
    </Container>
);

const ImpactComponent = () => (
    <div>
        <Image className="gs-impact-bg-t bg-light-blue" src={HOME_IMAGE.bg_impact_t} />
        <Container fluid className="bg-light-blue">
            <Container className="gs-block gs-home-impact-block pt-0 pb-0">
                <div className="gs-home-impact-text">
                    <h3 className="text-center font-weight-bold pb-4">Our Impact</h3>
                    <div className="text-h3">
                        <Row className="pb-3">
                            <Col xs={5}>
                                <h3 className="text-right text-aqua pb-3"><b>11</b></h3>
                            </Col>
                            <Col xs={7} className="p-0">
                                <h3 className="text-left impact-font-size"><b>Universities</b></h3>
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col xs={5}>
                                <h3 className="text-right text-pink pb-3"><b>100+</b></h3>
                            </Col>
                            <Col xs={7} className="p-0">
                                <h3 className="text-left impact-font-size"><b>Course Partnerships</b></h3>
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col xs={5}>
                                <h3 className="text-right text-dark-blue"><b>15,000</b></h3>
                            </Col>
                            <Col xs={7} className="p-0">
                                <h3 className="text-left impact-font-size"><b>Students Engaged</b></h3>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </Container>
        <Image className="gs-impact-bg-b" src={HOME_IMAGE.bg_impact_b} />
    </div>
);

const PortfolioComponent = () => {
    const linkOnClickHandler = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div className="gs-home-portfolio-section">
            <Row className="justify-items-center pb-4">
                <Col>
                    <h3 className="text-center font-weight-bold pb-5">Our Programs</h3>
                </Col>
            </Row>
            <Container fluid className="gs-block gs-home-portfolio-block p-0">
                <div className="gs-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={5} sm={4} className="p-0" />
                        <Col lg={6} md={7} sm={8} className="p-0">
                            <Image src={HOME_IMAGE.htg} className="w-100" fluid />
                        </Col>
                    </Row>
                    <div className="gs-home-portfolio-text gs-home-portfolio-left" style={{ borderLeft: '1.5rem solid #FED36A' }} data-aos="fade-up">
                        <h4>Hack The Globe</h4>
                        <br />
                        <p>
                            Canada’s first multidisciplinary social impact hackathon. Partnered with organizations like BCG, Google, and
                            the Gates Foundation, Hack the Globe challenges students across disciplines (e.g. engineering, business, medicine)
                            to build a social enterprise tackling the world’s most pressing problems over 2 weeks.
                        </p>
                        <NavLink to={ROUTES.HACK_THE_GLOBE}><Button onClick={linkOnClickHandler}>Learn More</Button></NavLink>
                    </div>
                </div>
            </Container>
            <Container fluid className="gs-block gs-home-portfolio-block p-0">
                <div className="gs-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={7} sm={8} className="p-0 w-md-50">
                            <Image src={HOME_IMAGE.speaker_panel} className="w-100" fluid />
                        </Col>
                        <Col lg={6} md={5} sm={4} className="p-0 w-md-50" />
                    </Row>
                    <div className="gs-home-portfolio-text gs-home-portfolio-right" style={{ borderLeft: '1.5rem solid #EC7070' }} data-aos="fade-up">
                        <h4>Thought Leader Speaker Panel</h4>
                        <br />
                        <p>
                            Every March, we host a first-of-its-kind discussion by bringing together global thought leaders from NGOs,
                            tech, business, and academia to discuss the promises and challenges of new technologies in addressing
                            pressing global problems.
                        </p>
                        <NavLink to={ROUTES.PANEL}><Button onClick={linkOnClickHandler}>Learn More</Button></NavLink>
                    </div>
                </div>
            </Container>
            <Container fluid className="gs-block gs-home-portfolio-block p-0">
                <div className="gs-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={5} sm={4} className="p-0 w-md-50" />
                        <Col lg={6} md={7} sm={8} className="p-0 w-md-50">
                            <Image src={HOME_IMAGE.curriculum} className="w-100" fluid />
                        </Col>
                    </Row>
                    <div className="gs-home-portfolio-text gs-home-portfolio-left" style={{ borderLeft: '1.5rem solid #B6E2E1' }} data-aos="fade-up">
                        <h4>Curriculum</h4>
                        <br />
                        <p>
                            We work with universities across Canada, building curricula for 100+ courses, to shift the way students explore the
                            world’s most pressing issues and approach the tools needed to effectively tackle them.
                        </p>
                        <NavLink to={ROUTES.CURRICULUM}><Button onClick={linkOnClickHandler}>Learn More</Button></NavLink>
                    </div>
                </div>
            </Container>
            <Container fluid className="gs-block gs-home-portfolio-block p-0">
                <div className="gs-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={7} sm={8} className="p-0 w-md-50">
                            <Image src={HOME_IMAGE.local_events} className="w-100" fluid />
                        </Col>
                        <Col lg={6} md={5} sm={4} className="p-0 w-md-50" />
                    </Row>
                    <div className="gs-home-portfolio-text gs-home-portfolio-right" style={{ borderLeft: '1.5rem solid #EC9783' }} data-aos="fade-up">
                        <h4>Local Events</h4>
                        <br />
                        <p>
                            Partnering with over 20 student organizations and institutions across Canada, we provide students with hands-on,
                            out-of-classroom opportunities including Speaker Series, Design Challenges, and Student Fairs that complement
                            students’ learnings from their curriculum.
                        </p>
                        <NavLink to={ROUTES.LOCAL_EVENTS}><Button onClick={linkOnClickHandler}>Learn More</Button></NavLink>
                    </div>
                </div>
            </Container>
        </div>
    );
};

const ContactComponent = () => (
    <Container className="gs-block gs-home-contact">
        <h3 className="text-center pb-3" data-aos="fade-up">Eager to Make a Difference?</h3>
        <Row className="pb-3">
            <Col md className="pb-3">
                <h5 className="pb-3 text-center" data-aos="fade-up">Interested in Partnering With Us?</h5>
                <p className="text-center" data-aos="fade-up">
                    Global Spark is a hub to connect and engage with the brightest and most passionate students across Canada.
                    Whether you’re a corporation, startup, NGO, or student organization, we are always looking to continue
                    expanding our partner network, and would love to connect!
                </p>
            </Col>
            <Col md>
                <h5 className="pb-3 text-center" data-aos="fade-up">Interested in Joining the Team?</h5>
                <p className="text-center" data-aos="fade-up">
                    Global Spark is growing rapidly - in the 4 years since our inception, we've expanded to engage nearly 15,000
                    students across Canada and hope to keep growing in the years to come. If you’re a driven and passionate student
                    or young professional, we’d love to hear from you!
                </p>
            </Col>
        </Row>
        <Row className="align-middle">
            <Col className="text-center" data-aos="fade-up">
                <Button href={`mailto: ${EXTERNAL_LINKS.EMAIL}`}>Contact Us</Button>
            </Col>
        </Row>
    </Container>
);

const BodyComponent = () => (
    <div id="home-body">
        <DescriptionComponent />
        <div className="gs-home-body-bg" style={{ backgroundImage: `url(${HOME_IMAGE.body_bg})` }}>
            <ObjectivesComponent />
            <ImpactComponent />
            <PortfolioComponent />
            <ContactComponent />
        </div>
    </div>
);

export default BodyComponent;
