import React from 'react';
import {
    Row, Col, Container,
} from 'react-bootstrap';

import { CURRICULUM_IMAGE } from '../util/constants';

const LandingComponent = () => (
    <Container
        data-aos="fade-right"
        fluid
        className="gs-block gs-landing"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${CURRICULUM_IMAGE.landing})` }}
    >
        <Container>
            <Row className="h-100 gs-landing-margin">
                <Col lg={3} className="pr-0" />
                <Col lg={9} className="text-right text-white text-image-alignment">
                    <h1 className="pb-2">Reshaping university education.</h1>
                    <p>
                        Collaborating with professors, institutional leaders and external partners, we develop content that changes
                        the way students learn about and apply multidisciplinary skill sets to global issues.
                    </p>
                </Col>
            </Row>
        </Container>
    </Container>
);

export default LandingComponent;
