import React, { useEffect } from 'react';
import { reportPageView } from '../util/analytics';

import BodyComponent from './BodyComponent';
import LandingComponent from './LandingComponent';
import CompaniesLogoComponent from '../home/CompaniesLogoComponent';
import { SPONSORS_IMAGE } from '../util/constants';

import './style.css';

const HomeComponent = () => {
    useEffect(() => {
        reportPageView();
        document.title = 'Local Events';
    });

    const logoSections = [
        [
            SPONSORS_IMAGE.ewb, SPONSORS_IMAGE.munk, SPONSORS_IMAGE.yncn, SPONSORS_IMAGE.wise,
            SPONSORS_IMAGE.degree_consulting, SPONSORS_IMAGE.sea, SPONSORS_IMAGE.ieee, SPONSORS_IMAGE.nsbe,
            SPONSORS_IMAGE.blueprint, SPONSORS_IMAGE.uweng, SPONSORS_IMAGE.uwpm, SPONSORS_IMAGE.enactus,
        ],
        [
            SPONSORS_IMAGE.bag, SPONSORS_IMAGE.wics, SPONSORS_IMAGE.sci, SPONSORS_IMAGE.uwimpact, SPONSORS_IMAGE.uwfossil,
            SPONSORS_IMAGE.uwclimate, SPONSORS_IMAGE.pbsn, SPONSORS_IMAGE.wecca, SPONSORS_IMAGE.wds,
        ],
    ];

    return (
        <div id="local-events">
            <LandingComponent />
            <CompaniesLogoComponent
                logoSections={logoSections}
                className="local-events-logos-section"
                heading="Organizations We’re Partnered With"
                desc="We partner with student organizations and university faculties across Canada to provide hands-on learning opportunities for students."
            />
            <BodyComponent />
        </div>
    );
};

export default HomeComponent;
