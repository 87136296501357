import React, { useEffect } from 'react';

import {
    Tabs, Tab, Container, Col, Row,
} from 'react-bootstrap';
import TeamSectionComponent from './TeamSectionComponent';
import { teamSections } from './util';
import './style.css';

const TeamComponent = () => {
    useEffect(() => {
        document.title = 'Our Team';
    });

    return (
        <div>
            <Container className="gs-block">
                <h1 className="text-center">Our Team</h1>
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <p className="text-center">
                            Global Spark is a multidisciplinary team of young professionals and student
                            leaders from across Canada. Founded in 2017 by Malik Ismail, the organization
                            has now grown to a community of over 70 individuals across Canada. Together,
                            we work to provide opportunities and experiences to spark the next generation
                            of leaders, ideas, and solutions to tackle the world’s most pressing challenges.
                        </p>
                    </Col>
                    <Col md={1} />
                </Row>
            </Container>

            <Container className="gs-block">
                <Tabs defaultActiveKey="Leadership" className="gs-team-tabs pt-2 pb-4" id="uncontrolled-tab-example">
                    <Tab eventKey="Leadership" title="Leadership">
                        <TeamSectionComponent teamSection={teamSections[0]} />
                    </Tab>
                    <Tab eventKey="HackTheGlobe" title="Hack The Globe">
                        <TeamSectionComponent teamSection={teamSections[1]} />
                    </Tab>
                    <Tab eventKey="ThoughtLeaderPanel" title="Thought Leader Panel">
                        <TeamSectionComponent teamSection={teamSections[2]} />
                    </Tab>
                    <Tab eventKey="Curriculum" title="Curriculum">
                        <TeamSectionComponent teamSection={teamSections[3]} />
                    </Tab>
                    <Tab eventKey="LocalEvents" title="Local Events">
                        <TeamSectionComponent teamSection={teamSections[4]} />
                    </Tab>
                    <Tab eventKey="Marketing And Digital" title="Marketing And Digital">
                        <TeamSectionComponent teamSection={teamSections[5]} />
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
};

export default TeamComponent;
