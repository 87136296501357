import React, { useEffect } from 'react';

import LandingComponent from './LandingComponent';
import BodyComponent from './BodyComponent';
import CompaniesLogoComponent from '../home/CompaniesLogoComponent';

import './style.css';
import { SPONSORS_IMAGE } from '../util/constants';

const HackTheGlobeComponent = () => {
    useEffect(() => {
        document.title = 'Hack the Globe';
    });

    const logoSections = [
        [
            SPONSORS_IMAGE.bcg,
            SPONSORS_IMAGE.google,
            SPONSORS_IMAGE.leap,
            SPONSORS_IMAGE.cfcf,
            SPONSORS_IMAGE.gates,
            SPONSORS_IMAGE.stc,
            SPONSORS_IMAGE.otpp,
            SPONSORS_IMAGE.ibm,
            SPONSORS_IMAGE.clearbanc,
            SPONSORS_IMAGE.loi,
            SPONSORS_IMAGE.ef,
        ],
        [
            SPONSORS_IMAGE.darwin,
            SPONSORS_IMAGE.agakhan,
            SPONSORS_IMAGE.soc,
            SPONSORS_IMAGE.gcc,
            SPONSORS_IMAGE.bcgcfca,
            SPONSORS_IMAGE.mastercard,
            SPONSORS_IMAGE.grameen,
            SPONSORS_IMAGE.mars,
            SPONSORS_IMAGE.voiceflow,
            SPONSORS_IMAGE.wwf,
        ],
        [
            SPONSORS_IMAGE.cyclica,
            SPONSORS_IMAGE.flipp,
            SPONSORS_IMAGE.ripple,
            SPONSORS_IMAGE.thinkdata,
            SPONSORS_IMAGE.united,
            SPONSORS_IMAGE.rumie,
            SPONSORS_IMAGE.global_medic,
            SPONSORS_IMAGE.nem,
            SPONSORS_IMAGE.bcch,
            SPONSORS_IMAGE.td,
            SPONSORS_IMAGE.bmo,
        ],
    ];

    return (
        <div id="htg">
            <LandingComponent />
            <CompaniesLogoComponent
                logoSections={logoSections}
                className="htg-logos-section"
                heading="Organizations We’ve Worked With"
                desc=""
            />
            <BodyComponent />
        </div>
    );
};

export default HackTheGlobeComponent;
